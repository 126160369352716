import type React from 'react';

import { isPresent } from '@import-io/typeguards';

import { Button } from 'common/components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'common/components/shadcn/dialog';

import type { ModalConfig } from './types';

interface ModalProps extends ModalConfig {
  readonly onOk: () => void;
  readonly onCancel: () => void;
}

export const AppModal: React.FC<ModalProps> = ({
  type,
  title,
  description,
  content,
  okText = 'OK',
  cancelText = 'Cancel',
  onOk,
  onCancel,
}) => {
  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          onCancel();
        }
      }}
      open
    >
      <DialogContent>
        {Boolean(title) ? (
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            {isPresent(description) ? <DialogDescription>{description}</DialogDescription> : null}
          </DialogHeader>
        ) : null}
        {content}
        <DialogFooter>
          {type === 'confirm' ? (
            <Button onClick={onCancel} variant="outline">
              {cancelText}
            </Button>
          ) : null}
          <Button onClick={onOk}>{okText}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
