import type { PropsWithChildren, ReactNode, MouseEvent } from 'react';
import { useEffect, useLayoutEffect, useRef, useCallback, useState, createContext, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { DEFAULT_TOOLTIP_CONFIG, placementTransforms } from 'common/components/tooltip/constants';
import { useAutoPlacement } from 'common/components/tooltip/use-auto-placement';
import { cn } from 'common/utils/cn';
import { useAnimationFrame } from 'features/extractor-builder-v2/hooks/use-animation-frame';

import { Easing, lerp } from './easing';
import type { TooltipConfig, TooltipPlacement, TooltipPosition } from './types';
import { useTooltipPosition } from './use-tooltip-position';

const DEFAULT_PLACEMENT: TooltipPlacement = 'top';

type TooltipState = TooltipConfig & {
  content: ReactNode;
  triggeredBy: HTMLElement;
  mouseEnterEvent?: MouseEvent;
};

interface TooltipContextProps {
  showTooltip: (params: TooltipState) => void;
  updateTooltip: (params: Partial<TooltipState>) => void;
  hideTooltip: () => void;
  activeTrigger: HTMLElement | null;
}

const placementCandidates: TooltipPlacement[] = ['top', 'bottom', 'right', 'left'];

const TooltipContext = createContext<TooltipContextProps | undefined>(undefined);

export const useTooltipContext = (): TooltipContextProps => {
  const context = useContext(TooltipContext);
  if (!context) {
    throw new Error('useTooltipContext must be used within a TooltipProvider');
  }
  return context;
};

type TooltipProviderProps = PropsWithChildren & TooltipConfig;

export const TooltipProvider = ({
  children,
  showDelay: defaultShowDelay = DEFAULT_TOOLTIP_CONFIG.showDelay, // default in ms
  hideDelay: defaultHideDelay = DEFAULT_TOOLTIP_CONFIG.hideDelay, // default in ms
  offset = DEFAULT_TOOLTIP_CONFIG.offset,
  minScreenOffset = DEFAULT_TOOLTIP_CONFIG.minScreenOffset,
  moveAnimationDuration = DEFAULT_TOOLTIP_CONFIG.moveAnimationDuration,
  scaleAnimationDuration = DEFAULT_TOOLTIP_CONFIG.scaleAnimationDuration,
  followMouse = DEFAULT_TOOLTIP_CONFIG.followMouse,
  placement: defaultPlacement = DEFAULT_TOOLTIP_CONFIG.placement,
}: TooltipProviderProps) => {
  const [tooltipState, setTooltipState] = useState<TooltipState | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const animationTimeRef = useRef<number>(0);

  const targetPositionRef = useRef<TooltipPosition | null>(null);
  const currentPositionRef = useRef<TooltipPosition | null>(null);

  const targetScaleRef = useRef<number>(0);
  const currentScaleRef = useRef<number>(0);

  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipContentRef = useRef<HTMLDivElement>(null);

  const curPlacement = useAutoPlacement({
    placement: tooltipState?.placement ?? defaultPlacement,
    candidates: placementCandidates,
    followMouse: tooltipState?.followMouse ?? followMouse,
    tooltipElement: tooltipContentRef.current,
    triggerElement: tooltipState?.triggeredBy,
    offset: tooltipState?.offset,
  });
  const prevPlacementRef = useRef<TooltipPlacement>(DEFAULT_PLACEMENT);

  useEffect(() => {
    if (!tooltipState) {
      prevPlacementRef.current = curPlacement;
    }
  }, [curPlacement, tooltipState]);

  const placement = useMemo(() => {
    if (tooltipState) {
      return curPlacement;
    } else {
      return prevPlacementRef.current;
    }
  }, [tooltipState, curPlacement]);

  const showTimeoutRef = useRef<number | null>(null);
  const hideTimeoutRef = useRef<number | null>(null);

  const handlePositionChange = useCallback((pos: TooltipPosition) => {
    targetPositionRef.current = pos;
    animationTimeRef.current = 0;
  }, []);

  const handleHoverStart = useCallback((pos: TooltipPosition) => {
    if (!currentPositionRef.current) {
      currentPositionRef.current = pos;
    }
    targetPositionRef.current = pos;
    animationTimeRef.current = 0;
  }, []);

  const handleHoverEnd = useCallback(() => {
    console.log('handleHoverEnd');
  }, []);

  useAnimationFrame(
    (deltaTime) => {
      if (!targetPositionRef.current || !currentPositionRef.current) {
        return;
      }
      animationTimeRef.current += deltaTime;

      const moveDuration = tooltipState?.moveAnimationDuration ?? moveAnimationDuration;
      if (moveDuration === 0) {
        currentPositionRef.current = {
          x: targetPositionRef.current.x,
          y: targetPositionRef.current.y,
        };
      } else {
        // Compute a normalized time value (clamped between 0 and 1).
        // deltaTime is the elapsed time since the last frame.
        const moveT = Easing.easeOutQuad(Math.min(animationTimeRef.current / moveDuration, 1));
        currentPositionRef.current = {
          x: lerp(currentPositionRef.current.x, targetPositionRef.current.x, moveT),
          y: lerp(currentPositionRef.current.y, targetPositionRef.current.y, moveT),
        };
      }

      const scaleDuration = tooltipState?.scaleAnimationDuration ?? scaleAnimationDuration;
      if (scaleDuration === 0) {
        currentScaleRef.current = targetScaleRef.current;
      } else {
        const scaleT = Easing.easeOutQuad(Math.min(deltaTime / scaleDuration, 1));
        currentScaleRef.current = lerp(currentScaleRef.current, targetScaleRef.current, scaleT);
      }

      if (!tooltipRef.current) {
        return;
      }

      tooltipRef.current.style.transform = `${placementTransforms[placement]} translate(${currentPositionRef.current.x}px, ${currentPositionRef.current.y}px) scale(${currentScaleRef.current})`;
    },
    isVisible ? 70 : 0, // capFPS value (if 0, the animation is disabled)
  );

  useEffect(() => {
    targetScaleRef.current = isVisible ? 1 : 0;
  }, [isVisible]);

  useLayoutEffect(() => {
    if (!tooltipRef.current || !tooltipContentRef.current) return;

    tooltipRef.current.style.zIndex = '99999';
    tooltipRef.current.style.transition = `width ${scaleAnimationDuration / 1000}s ease, height ${scaleAnimationDuration / 1000}s ease, opacity 0.1s ease`;

    if (Boolean(tooltipState?.content) && isVisible) {
      if (!tooltipContentRef.current || !tooltipRef.current) return;
      const contentWidth = tooltipContentRef.current.scrollWidth;
      const contentHeight = tooltipContentRef.current.scrollHeight;
      tooltipRef.current.style.width = `${contentWidth}px`;
      tooltipRef.current.style.height = `${contentHeight}px`;
      tooltipContentRef.current.style.opacity = '1';
    }
    if (!isVisible) {
      tooltipRef.current.style.width = `${0}px`;
      tooltipRef.current.style.height = `${0}px`;
      tooltipContentRef.current.style.opacity = '0';
    }
  }, [isVisible, scaleAnimationDuration, tooltipState?.content]);

  useTooltipPosition({
    triggerElement: tooltipState?.triggeredBy,
    tooltipElement: tooltipContentRef.current,
    mouseEnterEvent: tooltipState?.mouseEnterEvent?.nativeEvent,
    placement: placement,
    followMouse: tooltipState?.followMouse ?? followMouse,
    offset: tooltipState?.offset ?? offset,
    onPositionChange: handlePositionChange,
    onHoverStart: handleHoverStart,
    onHoverEnd: handleHoverEnd,
    minScreenOffset: tooltipState?.minScreenOffset ?? minScreenOffset,
  });

  const updateTooltip = useCallback((params: Partial<TooltipState>) => {
    setTooltipState((cur) => {
      if (!cur) return null;
      return { ...cur, ...params };
    });
  }, []);

  const showTooltip = useCallback(
    (params: TooltipState) => {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
        hideTimeoutRef.current = null;
      }
      setTooltipState(params);
      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current);
      }
      const delay = params.showDelay !== undefined ? params.showDelay : defaultShowDelay;
      showTimeoutRef.current = setTimeout(() => {
        setIsVisible(true);
        showTimeoutRef.current = null;
      }, delay) as any;
    },
    [defaultShowDelay],
  );

  const handleHide = useCallback(() => {
    setIsVisible(false);
    setTooltipState(null);
    hideTimeoutRef.current = null;
  }, []);

  const hideTooltip = useCallback(() => {
    if (showTimeoutRef.current) {
      clearTimeout(showTimeoutRef.current);
      showTimeoutRef.current = null;
    }
    const delay = tooltipState?.hideDelay ?? defaultHideDelay;
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    if (delay === 0) {
      handleHide();
      return;
    }
    hideTimeoutRef.current = setTimeout(() => {
      handleHide();
    }, delay) as any;
  }, [tooltipState?.hideDelay, defaultHideDelay, handleHide]);

  const providerValue = useMemo(
    () => ({
      showTooltip: showTooltip,
      hideTooltip: hideTooltip,
      updateTooltip: updateTooltip,
      activeTrigger: tooltipState?.triggeredBy ?? null,
    }),
    [showTooltip, hideTooltip, updateTooltip, tooltipState?.triggeredBy],
  );

  const tooltipPortal = useMemo(() => {
    return createPortal(
      <div
        className={cn(
          // We add a transition for opacity and also let the inline style (set via the effect) animate width/height.
          'transition-opacity absolute top-0 left-0 bg-foreground border border-border/20 rounded text-card pointer-events-none overflow-hidden',
          { 'opacity-100': isVisible, 'opacity-0': !isVisible },
        )}
        ref={tooltipRef}
        role="tooltip"
      >
        <div className={cn('w-max delay-150 transition-opacity whitespace-nowrap py-1 px-2 text-xs')} ref={tooltipContentRef}>
          {tooltipState?.content ?? ''}
        </div>
      </div>,
      document.body,
    );
  }, [isVisible, tooltipState]);

  return (
    <TooltipContext.Provider value={providerValue}>
      {children}
      {tooltipPortal}
    </TooltipContext.Provider>
  );
};
