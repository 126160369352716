import LogoDark from 'app/components/LogoDark';
import { AppLink } from 'common/components/AppLink';
import { DASH_PAGE_URL } from 'common/routes/routes-constants';

interface ErrorPageProps {
  readonly text?: string;
}

const defaultText = "We're sorry, something went wrong.";

const ErrorPage = ({ text = defaultText }: ErrorPageProps): JSX.Element => {
  return (
    <div className="flex flex-col justify-center items-center h-full bg-sidebar gap-4">
      <LogoDark />
      <h2 className="text-center text-card">{text}</h2>
      <AppLink className="text-center text-card hover:underline py-2 px-4 bg-card/10 rounded" to={DASH_PAGE_URL}>
        Click here to return to the application
      </AppLink>
    </div>
  );
};

export default ErrorPage;
