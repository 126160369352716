import { placementCenterOffsets } from 'common/components/tooltip/constants';

import type { TooltipPlacement, TooltipPosition } from './types';

/**
 * Parameters for computing the tooltip position.
 */
export type ComputeTooltipPositionParams = {
  element: HTMLElement;
  /** Where the tooltip should appear relative to the element. Defaults to 'top'. */
  placement?: TooltipPlacement;
  /** Offset in pixels between the element and tooltip. Defaults to 4. */
  offset?: number;
  /** If true, the tooltip follows the mouse on one axis. Defaults to false. */
  followMouse?: boolean;
  /** Optional mouse event to use for computing the position. */
  event?: MouseEvent;
};

/**
 * Signature for a function that computes a tooltip position given the
 * element's bounding rectangle, an offset, followMouse flag, and a mouse event.
 */
type ComputeFn = (rect: DOMRect, offset: number, followMouse: boolean, event?: MouseEvent) => TooltipPosition;

/** Computes the tooltip position for placement "top". */
const computeTop: ComputeFn = (rect, offset, followMouse, event) => {
  const x = followMouse && event ? event.clientX : rect.left + rect.width / 2;
  const y = rect.top - offset;
  return { x: x, y: y };
};

/** Computes the tooltip position for placement "bottom". */
const computeBottom: ComputeFn = (rect, offset, followMouse, event) => {
  const x = followMouse && event ? event.clientX : rect.left + rect.width / 2;
  const y = rect.bottom + offset;
  return { x: x, y: y };
};

/** Computes the tooltip position for placement "left". */
const computeLeft: ComputeFn = (rect, offset, followMouse, event) => {
  const x = rect.left - offset;
  const y = followMouse && event ? event.clientY : rect.top + rect.height / 2;
  return { x: x, y: y };
};

/** Computes the tooltip position for placement "right". */
const computeRight: ComputeFn = (rect, offset, followMouse, event) => {
  const x = rect.right + offset;
  const y = followMouse && event ? event.clientY : rect.top + rect.height / 2;
  return { x: x, y: y };
};

/**
 * A record mapping each placement to its corresponding computation function.
 */
export const positionComputers: Record<TooltipPlacement, ComputeFn> = {
  top: computeTop,
  bottom: computeBottom,
  left: computeLeft,
  right: computeRight,
};

/**
 * Computes the tooltip position using a single parameter object.
 *
 * @param params - The parameters required to compute the tooltip position.
 * @returns The computed tooltip position as an object with `x` and `y` coordinates.
 */
export function computeTooltipPosition({
  element,
  placement = 'top',
  offset = 4,
  followMouse = false,
  event,
}: ComputeTooltipPositionParams): TooltipPosition {
  if (!Boolean(element)) {
    return { x: 0, y: 0 };
  }
  const rect = element.getBoundingClientRect();
  const compute = positionComputers[placement];
  return compute(rect, offset, followMouse, event);
}

export function getPositionWithinBounds(
  pos: TooltipPosition,
  width: number,
  height: number,
  boundsRect: Pick<DOMRect, 'top' | 'left' | 'bottom' | 'right'>,
  placement: TooltipPlacement,
  gap: number = 16,
): TooltipPosition {
  const offsets = placementCenterOffsets[placement];
  const consideredWidthOffset = width * (1 + offsets.x);
  const consideredHeightOffset = height * (1 + offsets.y);

  return {
    x: Math.max(boundsRect.left + consideredWidthOffset + gap, Math.min(pos.x, boundsRect.right - consideredWidthOffset - gap)),
    y: Math.max(boundsRect.top + consideredHeightOffset + gap, Math.min(pos.y, boundsRect.bottom - consideredHeightOffset - gap)),
  };
}

/**
 * Returns an array of scrollable ancestors for a given node.
 */
export function getScrollParents(node: HTMLElement | null): (HTMLElement | Window)[] {
  const scrollParents: (HTMLElement | Window)[] = [];
  let parent = node?.parentElement;
  while (parent) {
    const style = window.getComputedStyle(parent);
    const overflow = style.overflow + style.overflowY + style.overflowX;
    if (/(auto|scroll)/.test(overflow)) {
      scrollParents.push(parent);
    }
    parent = parent.parentElement;
  }
  // Always include window as a scroll container.
  scrollParents.push(window);
  return scrollParents;
}
