import { LogoIcon } from 'app/components/LogoIcon';
import { LogoText } from 'app/components/LogoText';

const LogoDark = () => (
  <div className="flex items-baseline gap-2">
    <LogoIcon />
    <LogoText />
  </div>
);

export default LogoDark;
