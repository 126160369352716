import { useCallback, useMemo } from 'react';

import type { UseQueryStatesKeysMap } from 'nuqs';
import { useQueryState, useQueryStates } from 'nuqs';

import type { SortByProperty, SortByValue } from 'common/common-types';

export type UseListParamsValue<T extends object> = {
  search?: string;
  filter?: Partial<Record<keyof T, any>>;
  sort?: SortByValue<T>;
};

type CreateUseListQueryParamsConfig<T extends object> = {
  prefix?: string;
  filtersParser?: UseQueryStatesKeysMap<Partial<Record<keyof T, any>>>;
};

function withPrefix(name: string, prefix?: string): string {
  if (!Boolean(prefix)) {
    return name;
  }
  return `${prefix}_${name}`;
}

export function createUseListQueryParams<T extends object>({ filtersParser, prefix }: CreateUseListQueryParamsConfig<T>) {
  return function useListQueryParams() {
    const [search = '', setSearch] = useQueryState(withPrefix('search', prefix), {
      defaultValue: '',
      clearOnDefault: true,
    });

    const [sortProperty, setSortProperty] = useQueryState<SortByProperty<T>>(withPrefix('sortBy', prefix), {
      defaultValue: 'meta_created_at',
      parse: (v) => v as SortByProperty<T>,
      clearOnDefault: false,
    });

    const [sortDirection, setSortDirection] = useQueryState<'asc' | 'desc'>(withPrefix('sortDir', prefix), {
      defaultValue: 'desc',
      parse: (v) => v as 'asc' | 'desc',
      clearOnDefault: false,
    });

    const [filter, setFilter] = useQueryStates<Record<keyof T, any>>((filtersParser ?? {}) as any, {
      urlKeys: Object.keys(filtersParser ?? {}).reduce((acc, propName) => {
        acc[propName] = withPrefix(propName, prefix);
        return acc;
      }, {}),
    });

    const value: UseListParamsValue<T> = useMemo(() => {
      return {
        search: search,
        sort: {
          isDescending: sortDirection === 'desc',
          property: sortProperty,
        },
        filter: filter,
      };
    }, [search, sortProperty, sortDirection, filter]);

    const handleReset = useCallback(() => {
      void setSearch('');
      void setFilter({});
      void setSortProperty('meta_created_at');
      void setSortDirection('desc');
    }, [setFilter, setSearch, setSortDirection, setSortProperty]);

    return {
      search: search,
      setSearch: setSearch,
      sortProperty: sortProperty,
      setSortProperty: setSortProperty,
      sortDirection: sortDirection,
      setSortDirection: setSortDirection,
      filter: filter as Partial<Record<keyof T, any>>,
      setFilter: setFilter,
      value: value,
      resetAll: handleReset,
      pathname: location.pathname,
    };
  };
}
