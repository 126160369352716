// Add to the file only lazy loaded components or true common stuff like context, providers and redirects
import { lazy } from 'react';

export { default as ShortExtractorUrlRedirect } from './selected/ShortExtractorUrlRedirect';

export const ExtractorsMain = lazy(() => import('features/extractors/ExtractorsMain'));
export const FirstExtractorRedirect = lazy(() => import('features/extractors/FirstExtractorRedirect'));

export const SelectedExtractor = lazy(() => import('features/extractors/selected/SelectedExtractor'));

export const ExtractorRunHistory = lazy(() => import('features/extractors/history/ExtractorRunHistory'));

export const ExtractorSettings = lazy(() => import('features/extractor-settings/ExtractorSettingsPage'));

export const ExtractorInputSettings = lazy(() => import('features/extractor-inputs/ExtractorInputsPage'));

export const ExtractorIntegrate = lazy(() => import('features/extractors/integrate/ExtractorIntegrate'));

export const ExtractorConfigHistory = lazy(() => import('features/extractors/history/ExtractorConfigHistory'));
