import { ReportType } from '@import-io/types/report-types';

import COLORS from 'common/constants/colors';

// TODO: compare with CrawlRunStatus
export const RUN_STATUS_CONFIG = {
  STARTED: {
    spin: true,
    name: 'Started',
    slug: 'STARTED',
    color: COLORS.IO_STEEL,
    icon: 'RedoOutlined',
  },
  PENDING: {
    spin: true,
    name: 'Pending',
    slug: 'PENDING',
    color: COLORS.IO_STEEL,
    icon: 'RedoOutlined',
  },
  CANCELLED: {
    spin: false,
    name: 'Cancelled',
    slug: 'CANCELLED',
    color: COLORS.WARNING,
    icon: 'ExclamationCircleOutlined',
  },
  FAILED: {
    spin: false,
    name: 'Failed',
    slug: 'FAILED',
    color: COLORS.DANGER,
    icon: 'CloseCircleOutlined',
  },
  COMPLETE: {
    spin: false,
    name: 'Completed',
    slug: 'COMPLETE',
    color: COLORS.SUCCESS,
    icon: 'CheckCircleOutlined',
  },
  FINISHED: {
    spin: false,
    name: 'Completed',
    slug: 'FINISHED',
    color: COLORS.SUCCESS,
    icon: 'CheckCircleOutlined',
  },
};

// Report Summary Fields
export const DISPLAYED_REPORT_FIELDS = {
  added: {
    title: 'Added',
  },
  changed: {
    title: 'Changed',
  },
  removed: {
    title: 'Removed',
  },
  rowsProcessed: {
    title: 'Total Rows',
  },
  linkedRecords: {
    title: 'Linked records',
  },
  unmatchedRecords: {
    title: 'Unmatched records',
  },
  numberOfExtractors: {
    title: 'Number of Extractors',
  },
};

export const RUN_FILE_TYPE_CONFIG = {
  csv: {
    name: 'CSV',
    attachment: 'csv',
    icon: 'FileTextOutlined',
  },
  xlsx: {
    name: 'Excel',
    attachment: 'xlsx',
    icon: 'FileExcelOutlined',
  },
};

// Report Types // TODO: migrate to TS
export const REPORT_TYPES = [
  {
    slug: ReportType.CRAWL_REPORT,
    features: {
      name: 'Data',
      shortName: 'data',
      uiType: 'matrix',
      permission: 'CRAWL_REPORT',
      icon: 'NoIcon',
      canAccess: false,
      canDuplicate: true,
      minRows: 1,
      maxRows: 1,
      minColumns: 1,
      minExtractorRuns: 1,
      hasMaster: false,
      hasPrimary: false,
      canClearCell: false,
      requireValidation: true,
    },
  },
  {
    slug: ReportType.CRAWL_DIFF,
    features: {
      name: 'Change',
      shortName: 'change',
      uiType: 'drag',
      permission: 'DIFF_MONITORING',
      icon: 'DiffFilled',
      minExtractorRuns: 2,
      canAccess: false,
      canDuplicate: false,
      requireExtractor: true,
    },
  },
];
REPORT_TYPES.forEach(Object.freeze);

export const REPORT_FEATURES = REPORT_TYPES.reduce((acc, { features, slug }) => ({ ...acc, [slug]: features }), {});

export const VALID_REPORT_TYPES = [ReportType.CRAWL_DIFF, ReportType.CRAWL_REPORT];

Object.freeze(REPORT_FEATURES);
