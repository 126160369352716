import { isPresent } from '@import-io/typeguards';

import type { CreateEntityManagerParams } from 'common/hooks/entity-manager/types';
import { queryClient } from 'common/query/query-constants';

type CreateUpdateEntityInCacheParams<T extends object> = Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

/**
 * Get an entity by id from react-query cache
 * @param config - Configuration for identifying the list and the unique identifier.
 */
export const createGetByIdInCache = <T extends object>(config: CreateUpdateEntityInCacheParams<T>) => {
  const { rootKey } = config;

  return (id?: string) => {
    if (!isPresent(id)) {
      return undefined;
    }
    return queryClient.getQueryData<T>([rootKey, id]);
  };
};
