import { useCallback, useMemo } from 'react';

import type { ResponseError } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { InfiniteData, QueryKey, RefetchOptions } from '@tanstack/query-core';
import { useInfiniteQuery } from '@tanstack/react-query';

import { constructListQueryKey, LIST_KEY } from 'common/hooks/entity-manager/construct-list-query-key';
import type {
  CreateEntityManagerParams,
  CrudConfig,
  GetListParams,
  UseListFn,
  UseListParams,
  UseListResult,
} from 'common/hooks/entity-manager/types';
import { queryClient } from 'common/query/query-constants';

const PAGE_SIZE = 30;
const INITIAL_FETCH_PAGES_COUNT = 10;
const INITIAL_FETCH_SIZE = PAGE_SIZE * INITIAL_FETCH_PAGES_COUNT; // 30 * 10 = 300;

export type CreateUseListParams<T extends object> = {
  queryFn: CrudConfig<T>['getList'];
} & Pick<CreateEntityManagerParams<T>, 'rootKey' | 'searchField' | 'idField' | 'defaultListParams'>;

export const createUseList = <T extends object>(config: CreateUseListParams<T>): UseListFn<T> => {
  const { rootKey, queryFn, defaultListParams } = config;

  return function useList(params: UseListParams<T> = {}): UseListResult<T> {
    const paramsWithDefaults = { ...defaultListParams, ...params };
    const { search = '', filter, sort } = paramsWithDefaults;

    const queryKey: QueryKey = constructListQueryKey(rootKey, paramsWithDefaults);

    const q = useInfiniteQuery<T[], ResponseError, InfiniteData<T[]>, QueryKey, number>({
      queryKey: queryKey,
      initialPageParam: 1,
      queryFn: ({ pageParam = 1 }) => {
        const isInitialFetch = pageParam === 1 && !Boolean(search);
        const pageSize = isInitialFetch ? INITIAL_FETCH_SIZE : PAGE_SIZE;

        const paramsForFetch: GetListParams<T> = {
          search: search,
          filter: filter,
          sort: sort,
          page: pageParam,
          pageSize: pageSize,
        };

        return queryFn(paramsForFetch);
      },
      getNextPageParam: (lastPage, allPages, lastPageParam) => {
        if (!isPresent(lastPage)) {
          return 1;
        }

        // if search param exists, fetch with normal pagination
        if (Boolean(search)) {
          return lastPage.length === PAGE_SIZE ? allPages.length + 1 : undefined;
        }

        // if search param is empty
        const wasInitialFetch = lastPageParam === 1;
        const wasAllFetched = wasInitialFetch ? lastPage.length < INITIAL_FETCH_SIZE : lastPage.length < PAGE_SIZE;

        if (wasAllFetched) {
          return undefined;
        }

        return wasInitialFetch ? INITIAL_FETCH_PAGES_COUNT + 1 : lastPageParam + 1;
      },
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    });

    const { data, isError, error, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = q;

    const queryItems = useMemo(() => {
      return data?.pages.flat() ?? [];
    }, [data]);

    const handleRefetch = useCallback(
      (options: RefetchOptions) => {
        queryClient.setQueryData<InfiniteData<T[]>>(queryKey, (curData) => ({
          pages: curData?.pages.slice(0, 1) ?? [],
          pageParams: curData?.pageParams.slice(0, 1) ?? [],
        }));
        return q.refetch(options);
      },
      [q, queryKey],
    );

    return {
      refetch: handleRefetch,
      isRefetching: q.isRefetching,
      items: q.isRefetching ? [] : queryItems,
      fetchNextPage: fetchNextPage,
      hasNextPage: hasNextPage,
      isFetchingNextPage: isFetchingNextPage,
      isPending: q.isRefetching || isPending,
      allFetched: !hasNextPage,
      isError: isError,
      error: error,
    };
  };
};

export const createInvalidateList = (rootKey: string) => {
  return function invalidateList() {
    queryClient.setQueriesData<InfiniteData<any[]>>(
      {
        predicate: (query) => {
          const key = query.queryKey;
          return key[0] === rootKey && key[1] === LIST_KEY;
        },
      },
      () => ({
        pages: [],
        pageParams: [],
      }),
    );
    void queryClient.invalidateQueries({
      predicate: (query) => {
        const key = query.queryKey;
        return key[0] === rootKey && key[1] === LIST_KEY;
      },
    });
  };
};
