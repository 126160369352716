import { GEO_COUNTRY_CODES } from 'common/constants/country-codes';
import { DEFAULT_EXTRACTOR_COUNTRY_CODE, DEFAULT_EXTRACTOR_PROXY_TYPE } from 'common/constants/site-constants';
import { ExtractionType } from 'features/extractors/forms/extractors-form-types';
import type { NewExtractorFormValues } from 'features/extractors/forms/new-extractor-form/new-extractor-form-types';

export const alpha2To3Codes = Object.fromEntries(GEO_COUNTRY_CODES.map((item) => [item.alpha2, item.alpha3]));

export const alpha3To2Codes = Object.fromEntries(GEO_COUNTRY_CODES.map((item) => [item.alpha3, item.alpha2]));

export const defaultFormValues: NewExtractorFormValues = {
  authUrl: '',
  iso3Country: DEFAULT_EXTRACTOR_COUNTRY_CODE,
  proxyType: DEFAULT_EXTRACTOR_PROXY_TYPE,
  extractionType: ExtractionType.BASIC,
  url: '',
};
