import type { TooltipConfig, TooltipPlacement, TooltipPosition } from 'common/components/tooltip/types';

export const DEFAULT_TOOLTIP_CONFIG: Required<TooltipConfig> = {
  placement: 'auto',
  followMouse: true,
  showDelay: 300,
  hideDelay: 150,
  offset: 4,
  moveAnimationDuration: 150,
  scaleAnimationDuration: 100,
  minScreenOffset: 8,
};

export const placementCenterOffsets: Record<TooltipPlacement, TooltipPosition> = {
  ['top']: { x: -0.5, y: -1 },
  ['right']: { x: 0, y: -0.5 },
  ['bottom']: { x: -0.5, y: 0 },
  ['left']: { x: -1, y: -0.5 },
};

export const placementTransforms: Record<TooltipPlacement, string> = Object.keys(placementCenterOffsets).reduce(
  (acc, placement: TooltipPlacement) => {
    const offsets = placementCenterOffsets[placement];
    acc[placement] = `translate(${(offsets.x * 100).toFixed()}%, ${(offsets.y * 100).toFixed()}%)`;
    return acc;
  },
  {},
) as Record<TooltipPlacement, string>;
