import { createContext, useContext } from 'react';

import type { ModalMethods } from './types';

export const AppModalContext = createContext<ModalMethods | undefined>(undefined);

export const useAppModal = (): ModalMethods => {
  const context = useContext(AppModalContext);
  if (!context) {
    throw new Error('useAppModal must be used within an AppModalProvider');
  }
  return context;
};
