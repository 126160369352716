import { combineReducers } from 'redux';

import { extractorReducers } from 'app/dash-old/reducers/extractor-reducers';

import portal from './portal';
import reports from './reports';

const dashReducer = combineReducers({
  extractors: extractorReducers,
  reports: reports,
  portal: portal,
});

export default dashReducer;
