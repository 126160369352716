import type { ForwardedRef, RefAttributes } from 'react';
import { forwardRef, useMemo } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { useLocation, NavLink } from 'react-router-dom';

import { useUrlSearchParamsString } from 'common/hooks/use-url-search-params-string';
import { cn } from 'common/utils/cn';

export type AppLinkProps = NavLinkProps &
  RefAttributes<HTMLAnchorElement> & {
    readonly preserveSearchParams?: boolean;
    readonly exact?: boolean;
  };

export const AppLink = forwardRef(function AppLinkInner(
  { preserveSearchParams = false, exact = false, className, to, ...props }: AppLinkProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  const { pathname } = useLocation();
  const search = useUrlSearchParamsString();

  const toPathname = useMemo(() => {
    if (typeof to === 'string') {
      const url = new URL(`https://placeholder.com/${to}`);
      return url.pathname;
    }
    return to.pathname!;
  }, [to]);

  const isActive = useMemo(() => {
    return exact ? pathname === toPathname : pathname.startsWith(toPathname);
  }, [toPathname, pathname, exact]);

  const preservedTo = useMemo(() => {
    if (!Boolean(preserveSearchParams)) {
      return to;
    }
    return `${to}${search}`;
  }, [preserveSearchParams, to, search]);

  return <NavLink className={cn(className, { active: isActive })} ref={ref} to={preservedTo} {...props} />;
});
