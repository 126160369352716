// easing.ts

/**
 * Linearly interpolates between start and end by t.
 * @param start The starting value.
 * @param end The target value.
 * @param t A normalized value between 0 and 1.
 * @returns The interpolated value.
 */
export const lerp = (start: number, end: number, t: number): number => {
  return start + (end - start) * t;
};

/**
 * Common easing functions.
 */
export const Easing = {
  linear: (t: number): number => t,
  easeInQuad: (t: number): number => t * t,
  easeOutQuad: (t: number): number => t * (2 - t),
  easeInOutQuad: (t: number): number => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
};
