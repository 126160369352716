import { useEffect } from 'react';

import type { ResponseError } from '@import-io/js-sdk';
import { authApi, endpoints } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { User } from '@import-io/types';
import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';

import { queryClient } from 'common/query/query-constants';
import { getLoginUrl } from 'common/utils/url-utils';
import { selectCurrentUser } from 'features/user/auth/user-auth-query';

import { GET_CURRENT_USER_API_KEY_QUERY_KEY, GET_CURRENT_USER_QUERY_KEY } from './user-auth-constants';

const LOGOUT_URL = `https://auth-api.${endpoints.rootDomain}/auth/logout`;

export const logout = () => {
  window.location.replace(LOGOUT_URL);
};

const redirectToLogin = () => {
  const fullUrl = `${getLoginUrl()}?redirect=${encodeURIComponent(window.location.href)}`;
  window.location.replace(fullUrl);
};

export const useFetchCurrentUser = (
  params: Omit<UseQueryOptions<User | null | undefined>, 'onError' | 'queryKey' | 'queryFn'> = {
    enabled: false,
    refetchOnMount: false,
  },
): UseQueryResult<User | null | undefined, ResponseError> => {
  const q = useQuery<User | null | undefined, ResponseError>({
    queryKey: [GET_CURRENT_USER_QUERY_KEY],
    queryFn: () => authApi.currentUser(),
    ...params,
  });

  const { error } = q;

  useEffect(() => {
    if (error) {
      redirectToLogin();
    }
  }, [error]);

  return q;
};

export const useGetApiKey = (): UseQueryResult<string, ResponseError> =>
  useQuery<string, ResponseError>({
    queryKey: [GET_CURRENT_USER_API_KEY_QUERY_KEY],
    queryFn: () => authApi.getApiKey(),
    refetchOnMount: false,
  });

export const useCreateApiKey = (): UseMutationResult<string, ResponseError> =>
  useMutation({
    mutationFn: (password: string) => authApi.createApiKey(password),
    onError: (e) => {
      console.error('Unable to create API key', e);
      void toast.error(`Unable to create API key: ${e.message}`);
    },
    onSuccess: (apiKey: string) => queryClient.setQueryData([GET_CURRENT_USER_API_KEY_QUERY_KEY], apiKey),
  });

export const useExitImpersonate = (): UseMutationResult<any, ResponseError, void> => {
  const user = selectCurrentUser();
  return useMutation({
    mutationFn: () =>
      fetch(`${endpoints.authBaseUrl}/auth/logout/impersonate`, {
        method: 'POST',
        redirect: 'manual',
        credentials: 'include',
      }),
    onSuccess: () => {
      const userId = user?.guid;
      if (!isPresent(userId)) {
        return;
      }
      window.location.replace(`https://hades.${endpoints.rootDomain}/accounts/${userId}`);
    },
  });
};
