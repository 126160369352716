import { isPresent } from '@import-io/typeguards';
import { useMutation } from '@tanstack/react-query';

import type { CreateEntityManagerParams, CrudConfig, UseCreateFn } from 'common/hooks/entity-manager/types';
import { createInvalidateList } from 'common/hooks/entity-manager/use-list';

export type CreateUseCreateParams<T extends object> = {
  queryFn: CrudConfig<T>['create'];
} & Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

export const createUseCreate = <T extends object>(config: CreateUseCreateParams<T>): UseCreateFn<T> => {
  const { rootKey, queryFn } = config;

  const invalidateList = createInvalidateList(rootKey);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return function useCreate(options = {}) {
    const { onSuccess, onError, ...other } = options;
    return useMutation({
      ...other,
      mutationFn: ({ data }) => {
        return isPresent(queryFn) ? queryFn(data) : Promise.reject('Not implemented');
      },
      onSuccess: (result, params, context) => {
        onSuccess?.(result, params, context);
        invalidateList();
      },
      onError: (e, params, context) => {
        onError?.(e, params, context);
      },
    });
  };
};
