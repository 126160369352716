/* eslint-disable */
// @ts-nocheck

'use client';

import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      className="toaster group"
      theme={theme as ToasterProps['theme']}
      richColors
      toastOptions={{
        classNames: {
          description: 'group-[.toast]:text-muted-foreground',
          actionButton: 'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton: 'group-[.toast]:bg-white group-[.toast]:text-black',
          error:
            'group toast group-[.toaster]:bg-destructive-background group-[.toaster]:border group-[.toaster]:border-destructive/70 group-[.toaster]:text-destructive group-[.toaster]:shadow-lg',
          success:
            'group toast group-[.toaster]:bg-success-background group-[.toaster]:border group-[.toaster]:border-success/70 group-[.toaster]:shadow-lg',
          warning:
            'group toast group-[.toaster]:bg-yellow group-[.toaster]:text-yellow-600 dark:group-[.toaster]:text-foreground group-[.toaster]:shadow-lg',
          info: 'group toast group-[.toaster]:bg-primary-background group-[.toaster]:border group-[.toaster]:border-primary/70 group-[.toaster]:text-primary group-[.toaster]:shadow-lg',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
