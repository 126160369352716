import type { ResponseError } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { Extractor } from '@import-io/types';
import type { InfiniteData } from '@tanstack/query-core';
import { useQuery } from '@tanstack/react-query';
import type { UseQueryResult } from '@tanstack/react-query/src/types';

import { LIST_KEY } from 'common/hooks/entity-manager/construct-list-query-key';
import type { UseListParamsValue } from 'common/hooks/use-list-query-params';
import { queryClient } from 'common/query/query-constants';
import {
  EXTRACTORS_KEY,
  getExtractorsList,
  useExtractorsList,
  useExtractorsQueryParams,
} from 'features/extractors/hooks/use-extractors-hooks';

export const FIRST_EXTRACTOR_KEY = 'first-extractor';

export const useFirstVisibleExtractor = (
  params?: UseListParamsValue<Extractor>,
  refetchEnabled?: boolean,
): UseQueryResult<Extractor | null, ResponseError> => {
  return useQuery<Extractor | null, ResponseError>({
    queryKey: [FIRST_EXTRACTOR_KEY],
    queryFn: async () => {
      try {
        const list = await getExtractorsList({ ...(params ?? {}), page: 1, pageSize: 1 });
        return list?.[0] ?? null;
      } catch (error) {
        console.log('Fetch extractor error:', error);
        return null;
      }
    },
    refetchOnMount: true,
    refetchInterval: Boolean(refetchEnabled) ? 10000 : false,
  });
};

export const useFirstVisibleExtractorGuid = () => {
  const { value: searchParams } = useExtractorsQueryParams();
  const { data: firstExtractor, isPending, isLoading } = useFirstVisibleExtractor(searchParams);
  const { items: extractors } = useExtractorsList(searchParams);

  return { guid: isPresent(extractors[0]) ? extractors[0].guid : firstExtractor?.guid, isLoading: isPending || isLoading };
};

export const useExtractorsExist = () => {
  const { data: firstExtractor } = useFirstVisibleExtractor();
  const listQueries = queryClient.getQueriesData<InfiniteData<Extractor[]>>({
    predicate: (query) => {
      const key = query.queryKey;
      return key[0] === EXTRACTORS_KEY && key[1] === LIST_KEY;
    },
  });

  return (
    listQueries.some(([_, data]) => {
      return (data?.pages?.flat()?.length ?? 0) > 0;
    }) || isPresent(firstExtractor)
  );
};

export const invalidateFirstExtractor = async () => {
  queryClient.removeQueries({ queryKey: [FIRST_EXTRACTOR_KEY] });
  await queryClient.invalidateQueries({ queryKey: [FIRST_EXTRACTOR_KEY] });
};
