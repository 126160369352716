import { useState, useEffect } from 'react';

export const useUrlSearchParamsString = (): string => {
  const [searchParams, setSearchParams] = useState(() => window.location.search);

  useEffect(() => {
    const handleUrlChange = () => {
      setSearchParams(window.location.search);
    };

    // Listen for 'popstate' events for back/forward navigation
    window.addEventListener('popstate', handleUrlChange);

    // Listen for 'pushstate' and 'replacestate' using a custom event dispatch
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    const updateState = (method: Function) => {
      return function (this: typeof window.history, ...args: any[]) {
        const result = method.apply(this, args);
        window.dispatchEvent(new Event('statechange'));
        return result;
      };
    };

    window.history.pushState = updateState(originalPushState);
    window.history.replaceState = updateState(originalReplaceState);

    window.addEventListener('statechange', handleUrlChange);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      window.removeEventListener('statechange', handleUrlChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return searchParams;
};
