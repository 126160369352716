import { isPresent } from '@import-io/typeguards';
import type { QueryKey } from '@tanstack/react-query';

import type { SortByValue } from 'common/common-types';

export const LIST_KEY = 'list';

/**
 * Converts a sort configuration into a string key.
 * @param sort - The sort configuration.
 */
export const stringifySortKey = <T>(sort: SortByValue<T>): string => {
  return `${String(sort.property)}:${sort.isDescending ? 'desc' : 'asc'}`;
};

/**
 * Converts a filter object into a string key.
 * @param filter - The filter object.
 */
export const stringifyFilterKey = <T>(filter?: Partial<Record<keyof T, any>>): string => {
  return isPresent(filter) ? JSON.stringify(filter) : '';
};

/**
 * Constructs the list query key with sub-keys.
 * @param rootKey - The root key for the list.
 * @param params - The parameters including search, filter, and sort.
 */
export const constructListQueryKey = <T extends object>(
  rootKey: string,
  params: {
    search?: string;
    filter?: Partial<Record<keyof T, any>>;
    sort?: SortByValue<T>;
  },
): QueryKey => {
  const { search = '', filter, sort } = params;
  return [
    rootKey,
    LIST_KEY,
    {
      search: search,
      filter: stringifyFilterKey(filter),
      sort: sort ? stringifySortKey(sort) : undefined,
    },
  ];
};
