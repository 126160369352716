// src/components/Modal/AppModalProvider.tsx
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { isPresent } from '@import-io/typeguards';

import { AppModal } from './AppModal';
import { AppModalContext } from './AppModalContext';
import type { ModalConfig, ModalInstance, ModalMethods, ModalType } from './types';

interface ModalState extends ModalConfig {
  resolve?: (value: boolean) => void;
  reject?: (reason?: any) => void;
}

export const AppModalProvider: React.FC<{ readonly children: React.ReactNode }> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState | null>(null);

  const openModal = useCallback((type: ModalType, config: ModalConfig): Promise<boolean> & ModalInstance => {
    let resolveFn: (value: boolean) => void;
    let rejectFn: (reason?: any) => void;

    const promise = new Promise<boolean>((resolve, reject) => {
      resolveFn = resolve;
      rejectFn = reject;

      setModalState({ ...config, type: type, resolve: resolveFn, reject: rejectFn });
    }) as Promise<boolean> & ModalInstance;

    const update: ModalInstance['update'] = (newConfig) => {
      setModalState((prev) => (prev ? { ...prev, ...newConfig } : null));
    };

    const destroy: ModalInstance['destroy'] = () => {
      setModalState(null);
      resolveFn(false); // Optionally resolve with false when destroyed
    };

    // Attach the update and destroy methods to the promise
    (promise as any).update = update;
    (promise as any).destroy = destroy;

    return promise as Promise<boolean> & ModalInstance;
  }, []);

  const handleOk = useCallback(() => {
    if (isPresent(modalState?.onOk)) {
      modalState.onOk();
    }
    modalState?.resolve?.(true);
    setModalState(null);
  }, [modalState]);

  const handleCancel = useCallback(() => {
    if (isPresent(modalState?.onCancel)) {
      modalState.onCancel();
    }
    modalState?.resolve?.(false);
    setModalState(null);
  }, [modalState]);

  const methods = useMemo<ModalMethods>(() => {
    const createMethod = (type: ModalType) => {
      return (config: ModalConfig): Promise<boolean> & ModalInstance => {
        return openModal(type, config);
      };
    };

    return {
      info: createMethod('info'),
      success: createMethod('success'),
      error: createMethod('error'),
      warn: createMethod('warn'),
      warning: createMethod('warning'),
      confirm: createMethod('confirm'),
    };
  }, [openModal]);

  return (
    <AppModalContext.Provider value={methods}>
      {children}
      {isPresent(modalState) && <AppModal {...modalState} onCancel={handleCancel} onOk={handleOk} />}
    </AppModalContext.Provider>
  );
};
