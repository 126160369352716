import type { Ref } from 'react';
import { useEffect, useCallback, useRef, useState } from 'react';

import type { TooltipPlacement, TooltipPosition } from './types';
import { computeTooltipPosition, getPositionWithinBounds, getScrollParents } from './utils';

export type UseTooltipPositionParams = {
  mouseEnterEvent?: MouseEvent;

  disabled?: boolean;
  /** If true, the tooltip will follow the mouse along the X or Y axis while hovered. */
  followMouse?: boolean;
  triggerElement?: HTMLElement | null;
  tooltipElement?: HTMLElement | null;
  /** Where the tooltip appears relative to the element. */
  placement?: TooltipPlacement;
  /** Offset in pixels between the element and tooltip. */
  offset?: number;
  /**
   * Optional callback called whenever the tooltip position is recalculated.
   * Useful to update the tooltip element’s style manually.
   */
  onPositionChange?: (position: TooltipPosition) => void;

  /**
   * Optional callback invoked when hovering starts.
   * Receives the current tooltip position as a parameter.
   */
  onHoverStart?: (position: TooltipPosition) => void;
  /**
   * Optional callback invoked when hovering ends.
   * Receives the current tooltip position as a parameter.
   */
  onHoverEnd?: (position: TooltipPosition) => void;

  minScreenOffset?: number;
};

export type UseTooltipPositionResult = {
  isHovering: boolean;
  positionRef: Ref<TooltipPosition>;
};

export function useTooltipPosition({
  followMouse = false,
  triggerElement,
  tooltipElement,
  placement = 'top',
  offset = 0,
  disabled = false,
  onPositionChange,
  onHoverStart,
  onHoverEnd,
  mouseEnterEvent,
  minScreenOffset,
}: UseTooltipPositionParams): UseTooltipPositionResult {
  const positionRef = useRef<TooltipPosition>({ x: 0, y: 0 });
  const lastEventRef = useRef<MouseEvent | undefined>(undefined);
  const [isHovering, setIsHovering] = useState(false);
  const [tooltipWidth, setTooltipWidth] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);

  /**
   * Updates the tooltip’s position using the mouse event.
   * The new position is stored in a ref and passed to the callback.
   */
  const updatePosition = useCallback(
    (event?: MouseEvent) => {
      if (!triggerElement) return;
      const newPos = computeTooltipPosition({
        element: triggerElement,
        placement: placement,
        offset: offset,
        followMouse: followMouse,
        event: event ?? lastEventRef.current,
      });
      const truncatedPos = getPositionWithinBounds(
        newPos,
        tooltipWidth,
        tooltipHeight,
        {
          left: 0,
          top: 0,
          right: window.innerWidth,
          bottom: window.innerHeight,
        },
        placement,
        minScreenOffset,
      );
      positionRef.current = truncatedPos;
      if (onPositionChange) {
        onPositionChange(truncatedPos);
      }
      lastEventRef.current = event;
    },
    [triggerElement, placement, offset, followMouse, tooltipWidth, tooltipHeight, minScreenOffset, onPositionChange],
  );

  useEffect(() => {
    if (!tooltipElement) return;
    const observer = new ResizeObserver((resize) => {
      setTooltipWidth(resize[0]?.borderBoxSize?.[0]?.inlineSize ?? 0);
      setTooltipHeight(resize[0]?.borderBoxSize?.[0]?.blockSize ?? 0);
    });
    observer.observe(tooltipElement);
    return () => {
      observer.disconnect();
    };
  }, [tooltipElement]);

  // --- New: Use MutationObserver on the trigger element to detect layout shifts ---
  useEffect(() => {
    if (!triggerElement) return;
    const observer = new MutationObserver(() => {
      // When a mutation occurs, update the tooltip’s position.
      updatePosition();
    });
    observer.observe(triggerElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });
    return () => {
      observer.disconnect();
    };
  }, [triggerElement, updatePosition]);

  useEffect(() => {
    if (!triggerElement) return;
    const observer = new IntersectionObserver(
      (entries) => {
        // Even if the element stays visible, a layout shift can change the intersection details.
        entries.forEach(() => {
          updatePosition();
        });
      },
      {
        // Multiple thresholds to catch even small changes.
        threshold: [0, 0.25, 0.5, 0.75, 1],
      },
    );
    observer.observe(triggerElement);
    return () => {
      observer.disconnect();
    };
  }, [triggerElement, updatePosition]);

  useEffect(() => {
    if (!triggerElement) return;
    const scrollParents = getScrollParents(triggerElement);
    const handleScroll = () => updatePosition();
    scrollParents.forEach((parent) => {
      parent.addEventListener('scroll', handleScroll);
    });
    return () => {
      scrollParents.forEach((parent) => {
        parent.removeEventListener('scroll', handleScroll);
      });
    };
  }, [triggerElement, updatePosition]);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!triggerElement || !followMouse) return;
      updatePosition(event);
    },
    [triggerElement, followMouse, updatePosition],
  );

  const handleMouseEnter = useCallback(
    (event: MouseEvent) => {
      if (!triggerElement) return;
      setIsHovering(true);
      // Calculate the initial position.
      updatePosition(event);
      // Notify that hovering has started, along with the current position.
      onHoverStart?.(positionRef.current);
      // If following the mouse, add a mousemove listener.
      if (followMouse) {
        triggerElement.addEventListener('mousemove', handleMouseMove);
      }
    },
    [triggerElement, followMouse, updatePosition, handleMouseMove, onHoverStart],
  );

  useEffect(() => {
    if (!mouseEnterEvent) {
      return;
    }
    handleMouseEnter(mouseEnterEvent);
  }, [handleMouseEnter, mouseEnterEvent]);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
    // Notify that hovering has ended.
    onHoverEnd?.(positionRef.current);
    triggerElement?.removeEventListener('mousemove', handleMouseMove);
  }, [triggerElement, handleMouseMove, onHoverEnd]);

  useEffect(() => {
    if (disabled || !triggerElement) {
      return;
    }

    triggerElement.addEventListener('mouseenter', handleMouseEnter);
    triggerElement.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      triggerElement.removeEventListener('mouseenter', handleMouseEnter);
      triggerElement.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [disabled, triggerElement, handleMouseEnter, handleMouseLeave]);

  return { isHovering: isHovering, positionRef: positionRef };
}
