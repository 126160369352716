import { IS_PRODUCTION } from '@import-io/js-sdk/constants/host-constants';
import { PlanType } from '@import-io/types/user-subscription-types';

import { REPORT_TYPES } from 'app/dash-old/lib/constants/reports';
import type { Plan, ValidPlanType, SubscriptionFeatureFlags } from 'features/user/subscription/subscription-types';

export const PLAN_TITLES: Record<string, string> = {
  free: 'Free plan',
  essential: 'Essential plan',
  professional: 'Professional plan',
  enterprise: 'Enterprise plan',
  essential_annual: 'Essential plan',
  professional_annual: 'Professional plan',
  enterprise_annual: 'Enterprise plan',
  flex200: 'Flex 200 plan',
  flex600: 'Flex 600 plan',
  flex1200: 'Flex 1200 plan',
  flex2400: 'Flex 2400 plan',
  flex6000: 'Flex 6000 plan',
  project_flex: 'Project plan',
  desktop_conversion: 'Desktop Conversion plan',
  desktop_conversion_annual: 'Desktop Conversion plan',
  trial: 'Trial plan',
  free_trial: 'Trial plan',
  essential400417: 'Essential plan',
  essential_annual400417: 'Essential plan',
  professional400417: 'Professional plan',
  professional_annual400417: 'Professional plan',
  enterprise400417: 'Enterprise plan',
  enterprise_annual400417: 'Enterprise plan',
  enterprise_flex500617: 'Enterprise plan',
  essential617: 'Essential plan',
  essential_annual617: 'Essential plan',
  professional_flex100617: 'Professional plan',
  professional_flex50817: 'Professional plan',
  enterprise_flex250817: ' Enterprise plan',
  premium_flex1000817: 'Premium plan',
  [PlanType.TRIAL]: 'Trial Plan (14 days)',
  [PlanType.STARTER]: 'Starter Plan',
  [PlanType.STANDARD]: 'Standard Plan',
  [PlanType.ADVANCED]: 'Developer Plan',
  [PlanType.DEACTIVATED]: 'Deactivated Plan',
};

export const FREE_PRICE = 'Free'; // TODO: move to frontend mono repository

// TODO: move to frontend mono repository
export const TRIAL_PLAN: Plan = {
  description: [
    '14 days free',
    '500 queries',
    'Point and click training',
    'Authenticated extraction',
    'Interactive extraction',
    'Download images and files',
    'Capture screenshots',
    'CSV, Excel, JSON output',
    'Reporting',
    'API access and Webhooks',
    'E-mail and ticket support',
  ],
  disabled: true,
  topFeatures: [
    'Point and click training',
    'Interactive extraction',
    'E-mail and ticket support',
    'Capture screenshots',
    'API access and Webhooks',
  ],
  label: PLAN_TITLES[PlanType.TRIAL]!,
  price: FREE_PRICE,
  queries: 500,
  value: PlanType.TRIAL,
};

export const STARTER_PLAN: Plan = {
  description: [
    '5,000 queries',
    'Point and click training',
    'Authenticated extraction',
    'Interactive extraction',
    'Download images and files',
    'CSV, Excel, JSON output',
    'Reporting',
    'E-mail and ticket support',
  ],
  topFeatures: ['Point and click training', 'Interactive extraction', 'E-mail and ticket support'],
  label: PLAN_TITLES[PlanType.STARTER]!,
  price: '$399 / month',
  queries: 5_000,
  value: PlanType.STARTER,
};

export const STANDARD_PLAN: Plan = {
  description: [
    '20,000 queries',
    'Point and click training',
    'Authenticated extraction',
    'Interactive extraction',
    'Download images and files',
    'Capture screenshots',
    'CSV, Excel, JSON output',
    'Access to premium proxies*',
    'Reporting',
    'API access and Webhooks',
    'E-mail, ticket and chat support',
  ],
  topFeatures: [
    'Point and click training',
    'Interactive extraction',
    'E-mail, ticket, chat support',
    'Capture screenshots',
    'API access and Webhooks',
  ],
  label: PLAN_TITLES[PlanType.STANDARD]!,
  price: '$599 / month',
  queries: 20_000,
  value: PlanType.STANDARD,
};

export const ADVANCED_PLAN: Plan = {
  description: [
    '50,000 queries',
    'Point and click training',
    'Authenticated extraction',
    'Interactive extraction',
    'Download images and files',
    'Capture screenshots',
    'CSV, Excel, JSON output',
    'Access to premium proxies*',
    'Reporting',
    'API access and Webhooks',
    'E-mail, ticket, chat and phone support',
  ],
  topFeatures: [
    'Point and click training',
    'Interactive extraction',
    'E-mail, ticket, chat support',
    'Capture screenshots',
    'API access and Webhooks',
    'Access to premium proxies*',
  ],
  label: PLAN_TITLES[PlanType.ADVANCED]!,
  price: '$1099 / month',
  queries: 50_000,
  value: PlanType.ADVANCED,
};

export const DEACTIVATED_PLAN: Plan = {
  description: [],
  disabled: true,
  label: 'Deactivated',
  price: '',
  queries: 0,
  topFeatures: [],
  value: PlanType.DEACTIVATED,
};

export const VALID_PLANS_MAP: Record<ValidPlanType, Plan> = {
  [PlanType.TRIAL]: TRIAL_PLAN,
  [PlanType.STARTER]: STARTER_PLAN,
  [PlanType.STANDARD]: STANDARD_PLAN,
  [PlanType.ADVANCED]: ADVANCED_PLAN,
  [PlanType.DEACTIVATED]: DEACTIVATED_PLAN,
};

export const SUBSCRIPTION_FEATURE_MAP: Record<keyof SubscriptionFeatureFlags, string | string[]> = {
  canAdvancedExtract: 'ADV_EXTRACT',
  canAuthenticateExtractors: 'AUTHENTICATED_EXTRACTOR',
  canCreateDataReport: 'CRAWL_REPORT',
  canCreateDiffReport: 'DIFF_MONITORING',
  canDownloadContent: 'IMAGE_DOWNLOAD',
  canDownloadData: 'DOWNLOAD_DATA',
  canPublishToPortal: 'REPORT_PORTAL',
  canRecordInteraction: 'PAGE_INTERACTION',
  canScheduleRuns: 'SCHEDULER',
  canSolveCaptchas: 'CAPTCHA',
  canTagExtractors: 'EXTRACTOR_TAGS',
  canUseAnyReport: REPORT_TYPES.map((r) => r.features.permission),
  canUseApis: 'API_ACCESS',
  canUseCodeActions: 'CODE_ACTIONS',
  canUseExtractorWebhooks: 'EXTRACTOR_WEBHOOKS',
  canUseGeo: 'GEO',
  canUseHtmlExtraction: 'HTML_EXTRACTION',
  canUseScreenCapture: 'SCREEN_CAPTURE',
  hasAdvancedFeatures: 'ADVANCED_USER',
  orgManagement: 'ORG_MANAGEMENT',
};

export const DEFAULT_SUBSCRIPTION_FEATURE_FLAGS: SubscriptionFeatureFlags = {
  canAdvancedExtract: false,
  canAuthenticateExtractors: false,
  canCreateDataReport: false,
  canCreateDiffReport: false,
  canDownloadContent: false,
  canDownloadData: false,
  canPublishToPortal: false,
  canRecordInteraction: false,
  canScheduleRuns: false,
  canSolveCaptchas: false,
  canTagExtractors: false,
  canUseAnyReport: false,
  canUseApis: false,
  canUseCodeActions: false,
  canUseExtractorWebhooks: false,
  canUseGeo: false,
  canUseHtmlExtraction: false,
  canUseScreenCapture: false,
  hasAdvancedFeatures: false,
  orgManagement: false,
};

export const NON_STARTER_PLANS_KEYS: PlanType[] = [PlanType.ADVANCED];

export const PAID_PLANS_KEYS: PlanType[] = [...NON_STARTER_PLANS_KEYS];

export const DEFAULT_PLANS_KEYS: PlanType[] = [PlanType.TRIAL, ...PAID_PLANS_KEYS];

export const VALID_PLANS_KEYS: PlanType[] = [...DEFAULT_PLANS_KEYS, PlanType.DEACTIVATED];

// TODO: store in environment variables
export const RECURLY_KEY = IS_PRODUCTION ? 'ewr1-AebmPzzu6ZXA4aRiXXan32' : 'ewr1-1Cfz4EuQU0vvSnCZA5hgh7';

export const GET_SUBSCRIPTION_QUERY_KEY = 'GET_SUBSCRIPTION_QUERY_KEY';
export const SUBSCRIPTION_FEATURE_FLAGS_QUERY_KEY = 'SUBSCRIPTION_FLAGS_QUERY_KEY';
