import { lazy } from 'react';

export const ReportsMain = lazy(() => import('features/reports/ReportsMain'));
export const FirstReportRedirect = lazy(() => import('features/reports/FirstReportRedirect'));

export const SelectedReport = lazy(() => import('features/reports/selected/SelectedReport'));

export const NewReportPage = lazy(() => import('features/reports/new/NewReportPage'));
export const NewReportSettingsPage = lazy(() => import('features/reports/new/NewReportSettingsPage'));

export const ReportHistory = lazy(() => import('features/reports/history/ReportHistory'));
export const ReportSettingsPage = lazy(() => import('features/reports/settings/ReportSettingsPage'));
