// autoPlacement.ts

import { useState, useEffect, useMemo, useCallback } from 'react';

import type { TooltipPlacement, TooltipPosition } from './types';
import { computeTooltipPosition } from './utils';

export type AutoPlacementParams = {
  /** The placement prop provided by the consumer (may be 'auto'). */
  placement?: TooltipPlacement | 'auto';
  /** The element that triggers the tooltip. */
  triggerElement?: HTMLElement | null;
  /** The tooltip container element (used to measure its size). */
  tooltipElement?: HTMLElement | null;
  /** The offset between the trigger and the tooltip. */
  offset?: number;
  /** Whether the tooltip should follow the mouse (affects position computation). */
  followMouse?: boolean;
  /**
   * Optional candidate placements in order of priority.
   * Default order: ['top', 'bottom', 'right', 'left']
   */
  candidates?: TooltipPlacement[];
  /**
   * The minimum gap required between the tooltip and the container’s edge for a candidate to be considered valid.
   * Defaults to 8.
   */
  minGap?: number;
};

/**
 * Returns the bounding rectangle of the given container.
 * If container is window, returns the viewport boundaries.
 */
function getContainerRect(container: HTMLElement | Window): { left: number; top: number; right: number; bottom: number } {
  if (container === window) {
    return {
      left: 0,
      top: 0,
      right: window.innerWidth,
      bottom: window.innerHeight,
    };
  } else {
    const rect = (container as HTMLElement).getBoundingClientRect();
    return { left: rect.left, top: rect.top, right: rect.right, bottom: rect.bottom };
  }
}

/**
 * Checks whether the given rectangle is completely within the provided container rectangle,
 * leaving at least the specified gap on all sides.
 */
function isWithinContainer(
  rect: { left: number; top: number; right: number; bottom: number },
  containerRect: { left: number; top: number; right: number; bottom: number },
  gap: number,
  candidate: TooltipPlacement,
): boolean {
  if (candidate === 'top' || candidate === 'bottom') {
    return rect.top >= containerRect.top + gap && rect.bottom <= containerRect.bottom - gap;
  }
  return rect.left >= containerRect.left + gap && rect.right <= containerRect.right - gap;
}

/**
 * Computes the tooltip's absolute bounding rectangle for a given candidate placement.
 *
 * The transform origins are derived from your provider's mapping:
 *  - top:    "translate(-50%, -100%)" => computed position represents the bottom center.
 *  - right:  "translate(0%, -50%)"    => computed position represents the middle left.
 *  - bottom: "translate(-50%, 0%)"     => computed position represents the top center.
 *  - left:   "translate(-100%, -50%)"  => computed position represents the middle right.
 *
 * @param pos           The computed tooltip position.
 * @param tooltipWidth  The tooltip container's width.
 * @param tooltipHeight The tooltip container's height.
 * @param placement     The candidate placement.
 * @returns             The absolute bounding rectangle.
 */
function computeTooltipBoundingRectForPlacement(
  pos: TooltipPosition,
  tooltipWidth: number,
  tooltipHeight: number,
  placement: TooltipPlacement,
): { left: number; top: number; right: number; bottom: number } {
  let left = 0;
  let top = 0;
  switch (placement) {
    case 'top': // computed pos is bottom center
      left = pos.x - tooltipWidth / 2;
      top = pos.y - tooltipHeight;
      break;
    case 'bottom': // computed pos is top center
      left = pos.x - tooltipWidth / 2;
      top = pos.y;
      break;
    case 'right': // computed pos is middle left
      left = pos.x;
      top = pos.y - tooltipHeight / 2;
      break;
    case 'left': // computed pos is middle right
      left = pos.x - tooltipWidth;
      top = pos.y - tooltipHeight / 2;
      break;
    default:
      left = pos.x - tooltipWidth / 2;
      top = pos.y - tooltipHeight;
      break;
  }
  return { left: left, top: top, right: left + tooltipWidth, bottom: top + tooltipHeight };
}

/**
 * Iterates over candidate placements (default: top, bottom, right, left) and returns the first candidate
 * that would position the tooltip fully within the window's boundaries,
 * leaving at least `minGap` pixels between the tooltip and the window's edge.
 * If none fit, returns the first candidate.
 *
 * This function uses computeTooltipPosition (with no mouse event) to obtain the computed position,
 * then measures the tooltip element's dimensions.
 */
export function getBestPlacement(params: AutoPlacementParams): TooltipPlacement {
  const { placement = 'top', triggerElement, tooltipElement, offset = 0, candidates, minGap = 8, followMouse = false } = params;
  if (!triggerElement || !tooltipElement) {
    return 'top';
  }
  if (placement !== 'auto') {
    return placement;
  }
  const candidateOrder: TooltipPlacement[] = candidates || ['top', 'bottom', 'right', 'left'];

  const container = window;
  const containerRect = getContainerRect(container);
  const tooltipWidth = tooltipElement.offsetWidth;
  const tooltipHeight = tooltipElement.offsetHeight;

  for (const candidate of candidateOrder) {
    const pos = computeTooltipPosition({
      element: triggerElement,
      placement: candidate,
      offset: offset,
      followMouse: followMouse,
      event: undefined,
    });
    const boundingRect = computeTooltipBoundingRectForPlacement(pos, tooltipWidth, tooltipHeight, candidate);
    if (isWithinContainer(boundingRect, containerRect, minGap, candidate)) {
      return candidate;
    }
  }
  return candidateOrder[0] ?? 'top';
}

/**
 * A hook that calculates the actual tooltip placement based on the auto-placement logic.
 * Recalculates on window resize, scroll, and when the tooltip element's dimensions change.
 *
 * @param params AutoPlacementParams
 * @returns The computed TooltipPlacement.
 */
export function useAutoPlacement(params: AutoPlacementParams): TooltipPlacement {
  const { placement, triggerElement, tooltipElement, offset = 0, followMouse = false, candidates, minGap = 8 } = params;
  const [actualPlacement, setActualPlacement] = useState<TooltipPlacement>('top');

  // Memoize candidate order so it isn't recreated unnecessarily.
  const candidateOrder = useMemo(() => candidates || (['top', 'bottom', 'right', 'left'] as TooltipPlacement[]), [candidates]);

  // Recalc function is memoized to avoid recreating the function on every render.
  const recalc = useCallback(() => {
    if (!tooltipElement?.offsetHeight || !tooltipElement?.offsetWidth) {
      return;
    }
    const best = getBestPlacement({
      placement: placement,
      triggerElement: triggerElement,
      tooltipElement: tooltipElement,
      offset: offset,
      followMouse: followMouse,
      candidates: candidateOrder,
      minGap: minGap,
    });
    setActualPlacement(best);
  }, [placement, triggerElement, tooltipElement, offset, followMouse, candidateOrder, minGap]);

  useEffect(() => {
    if (placement !== 'auto') {
      setActualPlacement(placement ?? 'top');
      return;
    }

    recalc();
    window.addEventListener('resize', recalc, { passive: true });
    window.addEventListener('scroll', recalc, { passive: true });
    return () => {
      window.removeEventListener('resize', recalc);
      window.removeEventListener('scroll', recalc, true);
    };
  }, [placement, recalc]);

  // Observe changes in the tooltip element's dimensions using ResizeObserver.
  useEffect(() => {
    if (!tooltipElement) return;
    const observer = new ResizeObserver(() => {
      recalc();
    });
    observer.observe(tooltipElement);
    return () => {
      observer.disconnect();
    };
  }, [tooltipElement, recalc]);

  return actualPlacement;
}
