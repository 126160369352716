import { isPresent } from '@import-io/typeguards';
import { useMutation } from '@tanstack/react-query';

import { createDeleteEntityInCache } from 'common/hooks/entity-manager/create-delete-entity-in-cache';
import type { CreateEntityManagerParams, CrudConfig, RollbackFn, UseDeleteByIdFn } from 'common/hooks/entity-manager/types';
import { queryClient } from 'common/query/query-constants';

export type CreateUseDeleteByIdParams<T extends object> = {
  queryFn: CrudConfig<T>['deleteById'];
} & Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

export const createUseDeleteById = <T extends object>(config: CreateUseDeleteByIdParams<T>): UseDeleteByIdFn => {
  const { rootKey, queryFn } = config;

  return function useDeleteById(options = {}) {
    const { onSuccess, onError, onMutate, optimistic, ...other } = options;
    const deleteInCache = createDeleteEntityInCache<T>(config);

    // const invalidateList = createInvalidateList(rootKey);

    return useMutation({
      ...other,
      mutationFn: ({ id }: { id: string }) => {
        return isPresent(queryFn) ? queryFn(id) : Promise.reject('Not implemented');
      },
      onMutate: async (params) => {
        onMutate?.(params);
        await queryClient.cancelQueries({
          queryKey: [rootKey],
        });

        if (Boolean(optimistic)) {
          // Return a context object with the previous data to use for rollback in case of error
          return deleteInCache(params.id);
        }
      },
      onSuccess: (result, params, context) => {
        onSuccess?.(result, params, context);
        deleteInCache(params.id);
      },
      onError: (e, params, context?: { rollback?: RollbackFn }) => {
        onError?.(e, params, context);
        context?.rollback?.();
      },
    });
  };
};
