// Register the service worker (sw.ts)
import { logout } from 'features/user/auth/user-auth-hooks';

const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/requests-interceptor.worker.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
          // Removed updatefound reload logic.
          // If you need to notify the user about an update, you could do it here,
          // but don’t call window.location.reload() from here.
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  } else {
    console.warn('Service Workers are not supported in this browser.');
  }
};

// Register the service worker
registerServiceWorker();

// Listen for messages from the service worker
navigator.serviceWorker.addEventListener('message', (event) => {
  const { type } = event.data;
  if (type === 'LOGOUT') {
    logout(); // Trigger the logout function
  }
});

// Listen for controller change to reload the page only once
let refreshing = false;
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (refreshing) return;
  refreshing = true;
  window.location.reload();
});
