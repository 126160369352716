export const LogoIcon = () => {
  return (
    <svg fill="none" height="26" viewBox="0 0 20 26" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        className="fill-card"
        d="M6.82517 12.9145L12.9654 19.0253L19.1071 12.9158L12.9654 6.80499L6.82517 12.9145ZM0 6.10966L6.14045 12.2203L12.2819 6.11068L6.14045 0L0 6.10966ZM0 19.7082L6.14045 25.819L12.2819 19.7094L6.14045 13.5987L0 19.7082Z"
      />
    </svg>
  );
};
