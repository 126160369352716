import { useRef, useEffect, useCallback, useMemo } from 'react';

import { isPresent } from '@import-io/typeguards';

export const useAnimationFrame = (
  callback: (deltaTime: number) => void,
  capFPS: number = 60, // if 0, animation frame is disabled
) => {
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  // Calculate the minimum interval (in ms) between frames.
  const frameInterval = useMemo(() => {
    return Boolean(capFPS) ? 1000 / capFPS : 0;
  }, [capFPS]);

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        // Only call callback if enough time has passed or if no capFPS is set
        if (!capFPS || deltaTime >= frameInterval) {
          callback(deltaTime);
          previousTimeRef.current = time;
        }
      } else {
        previousTimeRef.current = time;
      }
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback, capFPS, frameInterval],
  );

  useEffect(() => {
    if (capFPS !== 0) {
      requestRef.current = requestAnimationFrame(animate);
    } else {
      if (isPresent(requestRef.current)) {
        cancelAnimationFrame(requestRef.current);
      }
    }
    return () => {
      if (isPresent(requestRef.current)) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [animate, capFPS]);

  // No return value needed
};
