import type { FormEvent, RefObject } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import type { ResponseError } from '@import-io/js-sdk';
import { subscriptionApi } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';
import type { UserSubscriptionData } from '@import-io/types/user-subscription-types';
import { PlanType } from '@import-io/types/user-subscription-types';
import { useRecurly } from '@recurly/react-recurly';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query/src/types';
import Form from 'antd/lib/form';
import { toast } from 'sonner';

import { sendEvent } from 'common/events/events-api';
import { EventType } from 'common/events/events-types';
import { DASH_PAGE_URL } from 'common/routes/routes-constants';
import { hasSomeValue } from 'common/utils/array-utils';
import { useFetchCurrentUser } from 'features/user/auth/user-auth-hooks';
import type { SubscriptionModalContextValue } from 'features/user/subscription/modal';
import { SubscriptionModalContext } from 'features/user/subscription/modal';
import {
  DEFAULT_PLANS_KEYS,
  DEFAULT_SUBSCRIPTION_FEATURE_FLAGS,
  GET_SUBSCRIPTION_QUERY_KEY,
  PAID_PLANS_KEYS,
  SUBSCRIPTION_FEATURE_FLAGS_QUERY_KEY,
  SUBSCRIPTION_FEATURE_MAP,
} from 'features/user/subscription/subscription-constants';
import type {
  SubscriptionFeatureFlags,
  SubscriptionUpgradeForm,
  UpdateSubscriptionParams,
  UseFinish,
  UsePlanType,
  UsePlanTypeProps,
  UseSubmitForm,
} from 'features/user/subscription/subscription-types';
import { SubscriptionMethod } from 'features/user/subscription/subscription-types';
import {
  canPerformQuery,
  getErrorMessage,
  isActiveSubscription,
  isVerifiedSubscription,
} from 'features/user/subscription/subscription-utils';

import { selectCurrentUser } from '../auth/user-auth-query';

import { invalidateSubscriptionQuery } from './subscription-query';

export const useSubmitForm = (): UseSubmitForm => {
  const ref = useRef<HTMLFormElement>(null);
  const [form] = Form.useForm<SubscriptionUpgradeForm>();
  const submit = form.submit;

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      submit();
    },
    [submit],
  );

  return {
    form: form,
    onSubmit: onSubmit,
    formRef: ref,
  };
};

export const useUpdateSubscription = (): UseMutationResult<UserSubscriptionData, ResponseError> => {
  const { email, guid } = selectCurrentUser();
  const { isUpgrade, hideModal } = useContext<SubscriptionModalContextValue>(SubscriptionModalContext);
  return useMutation({
    mutationFn: ({ planCode, recurlyToken }: UpdateSubscriptionParams) => subscriptionApi.update(guid, planCode, recurlyToken),
    onError: (e) => void toast.error(getErrorMessage(e, SubscriptionMethod.UPDATE)),
    onSuccess: async (_data, { planCode }) => {
      await invalidateSubscriptionQuery();
      const eventType = isUpgrade ? EventType.ACCOUNT_UPGRADED : EventType.ACCOUNT_DOWNGRADED;
      await sendEvent({
        type: eventType,
        data: {
          user: {
            id: guid,
            email: email,
            planCode: planCode,
          },
        },
      });
      hideModal();
    },
  });
};

export const useFinish = (formRef: RefObject<HTMLFormElement>): UseFinish => {
  const recurly = useRecurly();
  const htmlForm = formRef?.current as HTMLFormElement;
  const getRecurlyToken = useCallback(
    () =>
      new Promise<string>((res, rej) => {
        recurly.token(htmlForm, (err, token) => {
          if (err) {
            rej(err);
          } else {
            res(token.id);
          }
        });
      }),
    [htmlForm, recurly],
  );
  const { isPending, mutate } = useUpdateSubscription();

  const onFinish = useCallback(
    async (values: SubscriptionUpgradeForm) => {
      const { card, planCode } = values;
      const recurlyToken = isPresent(card) && !card.empty ? await getRecurlyToken() : undefined;
      mutate({ planCode: planCode, recurlyToken: recurlyToken });
    },
    [getRecurlyToken, mutate],
  );

  return {
    isLoading: isPending,
    onFinish: onFinish,
  };
};

export const usePlanType = ({ initialValue, setFieldValue, upgrade }: UsePlanTypeProps): UsePlanType => {
  const [planType, setPlanType] = useState<PlanType>(initialValue);

  const onChange = useCallback(
    (value: PlanType) => {
      setPlanType(value);
      setFieldValue('planCode', value);
    },
    [setFieldValue, setPlanType],
  );

  const selectableTypes = useMemo<PlanType[]>(() => {
    switch (initialValue) {
      case PlanType.DEACTIVATED:
      case PlanType.STARTER: {
        return upgrade ? PAID_PLANS_KEYS : [];
      }
      case PlanType.STANDARD: {
        return upgrade ? [PlanType.STANDARD, PlanType.ADVANCED] : [PlanType.STARTER];
      }
      case PlanType.ADVANCED: {
        return upgrade ? [PlanType.ADVANCED] : PAID_PLANS_KEYS;
      }
      default: {
        return DEFAULT_PLANS_KEYS;
      }
    }
  }, [upgrade, initialValue]);

  return {
    onChange: onChange,
    planType: planType,
    selectableTypes: selectableTypes,
  };
};

export const useFetchSubscription = (
  params: Omit<UseQueryOptions<UserSubscriptionData>, 'queryFn' | 'queryKey' | 'onError'> = {
    enabled: false,
    refetchOnMount: false,
  },
): UseQueryResult<UserSubscriptionData, ResponseError> => {
  const { data } = useFetchCurrentUser();
  const { guid = '' } = data ?? {};
  const q = useQuery<UserSubscriptionData, ResponseError>({
    queryKey: [GET_SUBSCRIPTION_QUERY_KEY, guid],
    queryFn: () => {
      return subscriptionApi.getSubscription(guid);
    },
    ...params,
  });

  const { error } = q;

  useEffect(() => {
    if (error) {
      console.error('Unable to get subscription', error);
      const errorMessage = getErrorMessage(error, SubscriptionMethod.GET);
      window.location.replace(DASH_PAGE_URL);
      void toast.error(errorMessage);
    }
  }, [error]);

  return q;
};

export const useCancelSubscription = (): UseMutationResult<unknown, ResponseError, void> => {
  const { email, guid } = selectCurrentUser();
  return useMutation({
    mutationFn: () => subscriptionApi.cancel(guid),
    onError: (e) => void toast.error(getErrorMessage(e, SubscriptionMethod.CANCEL)),
    onSuccess: async () => {
      await invalidateSubscriptionQuery();
      await sendEvent({
        type: EventType.ACCOUNT_CANCELLED,
        data: {
          user: {
            id: guid,
            email: email,
          },
        },
      });
    },
  });
};

export const useIsActiveAndVerifiedSubscriptionWithQueries = (): boolean => {
  const { data: subscription } = useFetchSubscription();

  return (
    isPresent(subscription) &&
    isActiveSubscription(subscription) &&
    isVerifiedSubscription(subscription) &&
    canPerformQuery(subscription)
  );
};

export const useSubscriptionFeatureFlags = (
  params: Omit<UseQueryOptions<SubscriptionFeatureFlags>, 'onError' | 'queryKey' | 'queryFn'> = {
    enabled: false,
    refetchOnMount: false,
  },
): SubscriptionFeatureFlags => {
  const { data: subscription = null } = useFetchSubscription();
  const { data: featureFlags = DEFAULT_SUBSCRIPTION_FEATURE_FLAGS } = useQuery<SubscriptionFeatureFlags>({
    ...params,
    queryKey: [SUBSCRIPTION_FEATURE_FLAGS_QUERY_KEY, subscription],
    queryFn: () => {
      if (isPresent(subscription)) {
        const { features } = subscription;
        const data = Object.fromEntries<boolean>(
          Object.entries(SUBSCRIPTION_FEATURE_MAP).map(([flag, feature]) => [flag, hasSomeValue<string>(features, feature)]),
        );
        return data as unknown as SubscriptionFeatureFlags;
      } else {
        return DEFAULT_SUBSCRIPTION_FEATURE_FLAGS;
      }
    },
  });
  return featureFlags;
};
