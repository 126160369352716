import type { ComponentType, JSX } from 'react';
import { useCallback, useMemo } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import BlockOutlined from '@ant-design/icons/BlockOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import Menu from 'antd/lib/menu';
import Popover from 'antd/lib/popover';

import { LogoIcon } from 'app/components/LogoIcon';
import type { WithClass } from 'common/common-types';
import { AppLink } from 'common/components/AppLink';
import { disabledFn } from 'common/constants/common-constants';
import { HELP_URL } from 'common/constants/site-constants';
import { useIsImpersonate } from 'common/hooks/use-is-impersonate';
import { useToggle } from 'common/hooks/use-toggle';
import * as routesConstants from 'common/routes/routes-constants';
import { usePortalEnabled } from 'features/portal/hooks/use-portal-enabled';
import { useShowUserManagement } from 'features/user/account/user-management/common/use-show-user-management';
import { logout, useExitImpersonate } from 'features/user/auth/user-auth-hooks';

interface SidebarLink extends NavLinkProps {
  icon: ComponentType<WithClass>;
  label: string;
}

const AppSidebar = (): JSX.Element | null => {
  const [open, toggleOpen] = useToggle();
  const onLogout = useCallback(() => {
    toggleOpen();
    logout();
  }, [toggleOpen]);
  const { mutate: exitImpersonate } = useExitImpersonate();
  const onExitImpersonate = useCallback(() => {
    toggleOpen();
    exitImpersonate();
  }, [exitImpersonate, toggleOpen]);
  const showUserManagement = useShowUserManagement();
  const showExitImpersonate = useIsImpersonate();

  const canPublishToPortal = usePortalEnabled();

  const appLinks: SidebarLink[] = useMemo(() => {
    return [
      {
        icon: HomeOutlined,
        label: 'home',
        to: routesConstants.DASH_PAGE_URL,
        end: true,
      },
      ...(canPublishToPortal
        ? [
            {
              icon: AppstoreOutlined,
              label: 'portal',
              to: routesConstants.PORTALS_PAGE_URL,
            },
          ]
        : []),
      {
        icon: BlockOutlined,
        label: 'extractors',
        to: routesConstants.EXTRACTORS_PAGE_URL,
      },
      {
        icon: LineChartOutlined,
        label: 'reports',
        to: routesConstants.REPORTS_PAGE_URL,
      },
    ];
  }, [canPublishToPortal]);

  const accountMenuItems = useMemo(
    () => [
      {
        key: routesConstants.SETTINGS_PAGE_ID,
        label: (
          <NavLink onClick={toggleOpen} to={routesConstants.ACCOUNT_SETTINGS_PAGE_URL}>
            <div>Settings</div>
          </NavLink>
        ),
      },
      {
        key: 'subscription',
        label: (
          <NavLink onClick={toggleOpen} to={routesConstants.ACCOUNT_BILLING_PAGE_URL}>
            <div>Subscription</div>
          </NavLink>
        ),
      },
      ...(showUserManagement
        ? [
            {
              key: routesConstants.USER_MANAGEMENT_PAGE_ID,
              label: (
                <NavLink onClick={toggleOpen} to={routesConstants.ACCOUNT_USER_MANAGEMENT_URL}>
                  <div>User Management</div>
                </NavLink>
              ),
            },
          ]
        : []),
      ...(showExitImpersonate
        ? [
            {
              key: 'exit-impersonate',
              label: <div onClick={onExitImpersonate}>Exit Impersonate</div>,
            },
          ]
        : []),
      {
        key: 'logout',
        label: <div onClick={onLogout}>Logout</div>,
      },
    ],
    [onLogout, onExitImpersonate, showExitImpersonate, showUserManagement, toggleOpen],
  );

  return (
    <div className="w-full h-full bg-sidebar">
      <aside className="flex flex-col h-full items-center justify-between border-r border-gray-border-inverse w-full">
        <nav className="flex flex-col items-center w-full">
          <AppLink className="block w-full mb-4" to={'/dash'}>
            <div className="h-[80px] w-full flex items-center justify-center border-b border-solid border-b-gray-border-inverse">
              <LogoIcon />
            </div>
          </AppLink>

          {appLinks.map(({ icon: Icon, label, end, ...restProps }) => (
            <NavLink
              {...restProps}
              className="size-[80px] flex flex-col items-center justify-center group [&.active]:opacity-100 opacity-70 hover:opacity-100"
              end={end}
              key={label}
            >
              <div className="transition-all duration-100 size-[42px] flex flex-col justify-center items-center rounded-lg group-hover:bg-white group-hover:bg-opacity-20 group-[.active]:bg-white bg-opacity-0 group-[.active]:bg-opacity-20 text-white">
                <Icon className="transition-all text-xl group-[.active]:scale-110" />
              </div>
              <div className="text-white capitalize">{label}</div>
            </NavLink>
          ))}
        </nav>
        <nav className="flex flex-column align-center full-width">
          <NavLink onClick={disabledFn} to={routesConstants.ACCOUNT_PAGE_URL}>
            <Popover
              content={<Menu items={accountMenuItems} mode="vertical" />}
              onOpenChange={toggleOpen}
              open={open}
              placement="right"
              trigger="click"
            >
              <div className="size-[80px] flex flex-col items-center justify-center group [&.active]:opacity-100 opacity-70 hover:opacity-100">
                <div className="transition-all duration-100 size-[42px] flex flex-col justify-center items-center rounded-lg group-hover:bg-white group-hover:bg-opacity-20 group-[.active]:bg-white bg-opacity-0 group-[.active]:bg-opacity-20 text-white">
                  <IdcardOutlined className="transition-all text-xl group-[.active]:scale-110" />
                </div>
                <div className="text-white capitalize">Account</div>
              </div>
            </Popover>
          </NavLink>
          <a
            className="size-[80px] flex flex-col items-center justify-center group [&.active]:opacity-100 opacity-70 hover:opacity-100"
            href={HELP_URL}
            rel="noreferrer noopener"
            target="_blank"
          >
            <div className="transition-all duration-100 size-[42px] flex flex-col justify-center items-center rounded-lg group-hover:bg-white group-hover:bg-opacity-20 group-[.active]:bg-white bg-opacity-0 group-[.active]:bg-opacity-20 text-white">
              <QuestionCircleOutlined className="transition-all text-xl group-[.active]:scale-110" />
            </div>
            <div className="text-white capitalize">Help</div>
          </a>
        </nav>
      </aside>
    </div>
  );
};

export default AppSidebar;
