/* eslint-disable @typescript-eslint/no-use-before-define */
import { crawlRunStatsApi } from '@import-io/js-sdk';
import type {
  CrawlRun,
  Extractor,
  RuntimeConfigurationNew,
  RuntimeConfigurationRecordNew,
  RuntimeConfigurationSettings,
  UserSubscriptionData,
} from '@import-io/types';
import { RuntimeConfigurationWrapper } from '@import-io/types';
import Modal from 'antd/lib/modal';
import { toast } from 'sonner';

import type { AppDispatch } from 'app/app-types';
import type { RootState } from 'common/common-types';
import { getUrlFromTraining } from 'common/utils/training-utils';
import { cancelRunApi, startRunApi } from 'features/crawl-run/crawl-run-api';
import * as Api from 'features/extractors/api/extractors-api';
import { selectMyExtractorByGuid } from 'features/extractors/extractors-selectors';
import { updateExtractorInCache } from 'features/extractors/hooks/use-extractors-hooks';
import { selectCurrentUser } from 'features/user/auth/user-auth-query';
import { selectSubscriptionFeatureFlags, selectSubscriptionQueryData } from 'features/user/subscription/subscription-query';
import { canPerformQuery } from 'features/user/subscription/subscription-utils';

export const SELECT_EXTRACTOR = 'SELECT_EXTRACTOR';
export const UPDATE_SELECTED_EXTRACTOR = 'UPDATE_SELECTED_EXTRACTOR';
export const FETCHING_RUNTIME_CONFIG_DASH = 'FETCHING_RUNTIME_CONFIG_DASH';
export const RUNTIME_CONFIG_RECEIVED_DASH = 'RUNTIME_CONFIG_RECEIVED_DASH';
export const UPDATE_RUNTIME_CONFIG_DASH = 'UPDATE_RUNTIME_CONFIG_DASH';
export const FETCHING_TRAINING = 'FETCHING_TRAINING';
export const RECEIVED_TRAINING = 'RECEIVED_TRAINING';
export const EXTRACTOR_RECEIVED = 'EXTRACTOR_RECEIVED';
export const CRAWL_RUN_STARTED = 'CRAWL_RUN_STARTED';
export const CRAWL_RUN_STOPPED = 'CRAWL_RUN_STOPPED';
export const STARTING_CRAWL_RUN = 'STARTING_CRAWL_RUN';
export const STOPPING_CRAWL_RUN = 'STOPPING_CRAWL_RUN';
export const TOGGLE_AUTO_RUN = 'TOGGLE_AUTO_RUN';
export const UPDATED_EXTRACTOR_SETTINGS = 'UPDATED_EXTRACTOR_SETTINGS';
export const UPDATING_EXTRACTOR_SETTINGS = 'UPDATING_EXTRACTOR_SETTINGS';
export const SET_SCREEN_CAPTURE = 'SET_SCREEN_CAPTURE';
export const SET_SCREEN_CAPTURE_TYPE = 'SET_SCREEN_CAPTURE_TYPE';
export const EXTRACTOR_QUALITY_DATA_REQUEST = 'EXTRACTOR_QUALITY_DATA_REQUEST';
export const EXTRACTOR_QUALITY_DATA_SUCCESS = 'EXTRACTOR_QUALITY_DATA_SUCCESS';
export const EXTRACTOR_QUALITY_DATA_FAILURE = 'EXTRACTOR_QUALITY_DATA_FAILURE';
export const CLEANUP_QUALITY_DATA = 'CLEANUP_QUALITY_DATA';
export const SET_HTML_EXTRACTION = 'SET_HTML_EXTRACTION';

export const updateSelectedExtractorState = (updates: Partial<Extractor>) => ({
  type: UPDATE_SELECTED_EXTRACTOR,
  updates: updates,
});

export const updatingExtractorSettings = () => ({ type: UPDATING_EXTRACTOR_SETTINGS });

export const updatedExtractorSettings = () => ({ type: UPDATED_EXTRACTOR_SETTINGS });
export function runtimeConfigReceived(
  runtimeConfiguration: Partial<RuntimeConfigurationRecordNew>,
  hasScreenCapture: boolean,
  screenCaptureType: RuntimeConfigurationSettings['screenCaptureType'],
  hasHtmlExtraction: boolean,
) {
  return (dispatch, getState) => {
    const state: RootState['dashboard']['extractors'] = (getState() as RootState).dashboard.extractors;

    // Default to state's screen capture if nothing was passed in
    const screenCapture = hasScreenCapture == null ? state.hasScreenCapture : hasScreenCapture;
    const captureType = screenCaptureType == null ? state.screenCaptureType : screenCaptureType;
    const htmlExtraction = hasHtmlExtraction == null ? state.hasHtmlExtraction : hasHtmlExtraction;

    dispatch({
      type: RUNTIME_CONFIG_RECEIVED_DASH,
      runtimeConfiguration: runtimeConfiguration,
      hasScreenCapture: screenCapture,
      screenCaptureType: captureType,
      hasHtmlExtraction: htmlExtraction,
    });
  };
}

export const updateRuntimeConfig = (newRuntimeConfig: RuntimeConfigurationNew) => ({
  type: UPDATE_RUNTIME_CONFIG_DASH,
  newRuntimeConfig: newRuntimeConfig,
});

function getTraining(extractor: Extractor) {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCHING_TRAINING });

      let training = await Api.fetchTrainingData(extractor);

      // With the introduction of PageSet we save the training differently and need to handle each case
      if (typeof training === 'string') training = JSON.parse(training);

      const initialUrl = getUrlFromTraining(training);

      dispatch({
        type: RECEIVED_TRAINING,
        training: training,
        initialUrl: initialUrl,
      });
    } catch (e) {
      dispatch({
        type: RECEIVED_TRAINING,
        training: null,
        initialUrl: '',
      });
      console.error('Error getting training, defaulting values', e);
    }
  };
}

export function getExtractorQualityStats(extractorId) {
  return async (dispatch) => {
    try {
      dispatch({ type: EXTRACTOR_QUALITY_DATA_REQUEST });
      const response = await crawlRunStatsApi.getExtractorQualityStats(extractorId);
      dispatch({ type: EXTRACTOR_QUALITY_DATA_SUCCESS, payload: response });
    } catch (error) {
      dispatch({ type: EXTRACTOR_QUALITY_DATA_FAILURE, payload: error });
    }
  };
}

export function cleanUpQualityData() {
  return (dispatch) => dispatch({ type: CLEANUP_QUALITY_DATA });
}

export function selectExtractor(selectedExtractor) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const { selectedExtractorGuid, autoRun } = state.dashboard.extractors;
    dispatch({ type: SELECT_EXTRACTOR, selectedExtractor: selectedExtractor });
    if (selectedExtractorGuid !== selectedExtractor.guid) {
      dispatch(getExtractorData(selectedExtractor));
    }
    if (autoRun) {
      dispatch(checkAndStartRun(selectedExtractor));
      dispatch(toggleAutoRun());
    }
  };
}

export function getExtractorData(extractor: Extractor) {
  return (dispatch) => {
    // TODO: do we need training?
    dispatch(getTraining(extractor));
    dispatch(cleanUpQualityData());
    dispatch(getExtractorQualityStats(extractor.guid));
  };
}

export function toggleHtmlExtractionForSelectedExtractor() {
  return changeRuntimeConfig((wrapper, state, dispatch) => {
    const newHasHtmlExtraction = !state.dashboard.extractors.hasHtmlExtraction;
    dispatch({ type: SET_HTML_EXTRACTION, hasHtmlExtraction: newHasHtmlExtraction });
    wrapper.shouldExtractHtml = newHasHtmlExtraction;
  });
}

export function toggleScreenCaptureForSelectedExtractor() {
  return changeRuntimeConfig((wrapper, state, dispatch) => {
    const { hasScreenCapture, screenCaptureType } = state.dashboard.extractors;
    const newHasScreenCapture = !hasScreenCapture;
    dispatch({ type: SET_SCREEN_CAPTURE, hasScreenCapture: newHasScreenCapture });
    wrapper.screenCapture = { hasScreenCapture: newHasScreenCapture, screenCaptureType: screenCaptureType };
  });
}

/**
 * Helper function to change params of runtimeConfig
 * It will call changer function to change runtimeConfig and save it to the server
 * @param changer
 */
function changeRuntimeConfig(changer: (wrapper: RuntimeConfigurationWrapper, state: RootState, dispatch: AppDispatch) => void) {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const extractor = state.dashboard.extractors.selectedExtractor;
    const runtimeConfigurationRecord = state.dashboard.extractors.runtimeConfiguration;
    const wrapper = new RuntimeConfigurationWrapper(runtimeConfigurationRecord!);

    changer(wrapper, state, dispatch);

    const newRuntimeConfig = wrapper.runtimeConfig;
    const updatedExtractor = await Api.setRuntimeConfig(extractor, newRuntimeConfig);
    dispatch(updateRuntimeConfig(newRuntimeConfig));
    if (updatedExtractor) {
      updateExtractorInCache({
        id: extractor!.guid,
        data: {
          ...extractor,
          ...updatedExtractor,
        },
      });
      dispatch(updateSelectedExtractorState(updatedExtractor));
    }
  };
}

export function toggleScreenCaptureTypeForSelectedExtractor() {
  return changeRuntimeConfig((wrapper, state, dispatch) => {
    const screenCaptureType = state.dashboard.extractors.screenCaptureType;
    const newScreenCaptureType = screenCaptureType === 'png' ? 'pdf' : 'png';
    dispatch({ type: SET_SCREEN_CAPTURE_TYPE, screenCaptureType: newScreenCaptureType });
    wrapper.screenCapture = { hasScreenCapture: true, screenCaptureType: newScreenCaptureType };
  });
}

// Running an extractor --------------------------
export function checkAndStartRun(extractor: Extractor) {
  return async (dispatch) => {
    const subscriptionData = selectSubscriptionQueryData();
    if (!subscriptionData || !canPerformQuery(subscriptionData)) {
      void toast.error("Can't perform query");
      return;
    }

    const canCompleteRun = await canCompleteRunForExtractor(extractor, subscriptionData);
    if (!canCompleteRun) {
      // Can't complete query - not enough credits
      Modal.confirm({
        content: 'Running this extractor will use all your monthly allowance. Are you sure you want to continue?',
        okText: "Yes, I'm sure",
        onOk: () => dispatch(startRun(extractor)),
        title: 'Warning',
      });
      return;
    }

    const { canAuthenticateExtractors } = selectSubscriptionFeatureFlags();
    if (!extractor.authUrl || !canAuthenticateExtractors) {
      // Not authenticated
      dispatch(startRun(extractor));
      return;
    }

    // it is an auth extractor, lets see if we have the credentials stored
    const isCredentialsStored = await getIsAuthenticationCredentialsStored(extractor.guid);
    if (isCredentialsStored) {
      dispatch(startRun(extractor));
      return;
    }
    // TODO: show auth modal and run
    void toast.error('Invalid extractor authentication config');
  };
}

export function startRun(extractor: Extractor) {
  return (dispatch) => {
    dispatch({ type: STARTING_CRAWL_RUN });
    try {
      let currentExtractor = extractor;

      if (extractor.parentTriggered && extractor.isChained) {
        // Let's find the root extractor that is not parent triggered
        while (currentExtractor.parentExtractorGuid) {
          const parentExtractor = selectMyExtractorByGuid(currentExtractor.parentExtractorGuid);
          if (!parentExtractor) {
            break;
          }
          currentExtractor = parentExtractor;
        }
      }
      void startRunApi(currentExtractor);
      dispatch({ type: CRAWL_RUN_STARTED });
    } catch (e) {
      dispatch(runFailed(e, extractor));
    }
  };
}

function runFailed(error: Error & { status?: number }, extractor: Extractor) {
  return (dispatch) => {
    console.error('failed to startRun', error);
    if (error.status == 400) {
      cancelRunOld(extractor.guid);
    }
    dispatch({ type: CRAWL_RUN_STOPPED });
  };
}

export async function getIsAuthenticationCredentialsStored(extractorGuid: string): Promise<boolean> {
  try {
    const extractor = await Api.getExtractorWithChild(extractorGuid, 'privateConfig');
    return !!(extractor?.credentialsGuid ?? extractor?.privateConfig?.credentialsGuid);
  } catch (err) {
    console.error('Unable to get extractor data with child object privateConfig:', err);
    return false;
  }
}

/**
 * @deprecated use React Query hook instead
 */
export function cancelRunOld(extractorGuid: string, crawlRun?: CrawlRun) {
  return (dispatch) => {
    try {
      dispatch({ type: STOPPING_CRAWL_RUN });
      const user = selectCurrentUser();
      const subscription = selectSubscriptionQueryData();
      const extractor = selectMyExtractorByGuid(extractorGuid);
      setTimeout(() => {
        dispatch({ type: CRAWL_RUN_STOPPED });
      }, 3000);
      void cancelRunApi({ crawlRun: crawlRun, extractor: extractor!, subscription: subscription, user: user });
    } catch (e) {
      console.error('failed to cancelRun', e);
    }
  };
}

export async function canCompleteRunForExtractor(extractor: Extractor, subscriptionData: UserSubscriptionData): Promise<boolean> {
  //If the plan is a non paid plan - check whether they have enough queries to run the crawl
  //Otherwise user is on a paid plan, so we do not need to check as we will bill query overages
  if (!subscriptionData.overageDisabled) {
    // Overage is enabled, so extractor can be launched
    return true;
  }

  const urlListLength = await Api.getUrlListLength(extractor);
  const queriesRemaining = subscriptionData.maxUsage - subscriptionData.usage;
  return urlListLength < queriesRemaining;
}

export function toggleAutoRun() {
  return (dispatch) => dispatch({ type: TOGGLE_AUTO_RUN });
}
