import type { InfiniteData } from '@tanstack/query-core';

import { LIST_KEY } from 'common/hooks/entity-manager/construct-list-query-key';
import type { CreateEntityManagerParams, DeleteByIdInCacheFn } from 'common/hooks/entity-manager/types';
import { queryClient } from 'common/query/query-constants';

type CreateDeleteEntityInCacheParams<T extends object> = Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

/**
 * Delete an item in the cache.
 * @param config - Configuration for identifying the list and the unique identifier.
 */
export const createDeleteEntityInCache = <T extends object>(config: CreateDeleteEntityInCacheParams<T>): DeleteByIdInCacheFn => {
  const { rootKey, idField } = config;

  return (id: string) => {
    const initialByIdQuery = queryClient.getQueryData<T>([rootKey, id]);
    const initialListQueries = queryClient.getQueriesData<InfiniteData<T[]>>({ queryKey: [rootKey, LIST_KEY] });

    queryClient.setQueriesData<InfiniteData<T[]>>(
      {
        queryKey: [rootKey, LIST_KEY],
      },
      (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          pages: oldData.pages.map((page) => page.filter((item) => item[idField] !== id)),
        };
      },
    );

    queryClient.removeQueries({
      queryKey: [rootKey, id],
    });

    return {
      rollback: () => {
        queryClient.setQueryData<T>([rootKey, id], initialByIdQuery);
        initialListQueries.forEach(([qKey, qData]) => {
          queryClient.setQueryData(qKey, qData);
        });
      },
    };
  };
};
