import { isGuid, isPresent } from '@import-io/typeguards';
import { type InfiniteData, useQuery } from '@tanstack/react-query';

import { LIST_KEY } from 'common/hooks/entity-manager/construct-list-query-key';
import type { CreateEntityManagerParams, CrudConfig, UseByIdFn } from 'common/hooks/entity-manager/types';
import { queryClient } from 'common/query/query-constants';

export type CreateUseByIdParams<T extends object> = {
  queryFn: CrudConfig<T>['getById'];
} & Pick<CreateEntityManagerParams<T>, 'rootKey' | 'idField'>;

export const createUseById = <T extends object>(config: CreateUseByIdParams<T>): UseByIdFn<T> => {
  const { rootKey, idField, queryFn } = config;

  return function useById(id?: string) {
    return useQuery({
      queryKey: [rootKey, id],
      queryFn: () => {
        return queryFn(id!);
      },
      initialData: () => {
        const listQueriesData = queryClient.getQueriesData<InfiniteData<T[]>>({
          queryKey: [rootKey, LIST_KEY],
          exact: false,
        });
        return isPresent(id)
          ? listQueriesData
              .flatMap(([_, data]) => {
                return data?.pages?.flat() ?? [];
              })
              .find((entity) => entity[idField] === id) ?? undefined
          : undefined;
      },
      enabled: isGuid(id),
      refetchOnMount: true,
      staleTime: 10000,
      initialDataUpdatedAt: 0,
    });
  };
};
