import { cn } from 'common/utils/cn';
import { ForwardedRef, forwardRef, useMemo } from 'react';

type LoadingSpinnerSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type LoadingSpinnerVariant = 'primary' | 'secondary' | 'background' | 'card';

const sizeToPx: Record<LoadingSpinnerSize, number> = {
  ['xs']: 12,
  ['sm']: 16,
  ['md']: 22,
  ['lg']: 32,
  ['xl']: 48,
};

const getColor = (variable: string) => getComputedStyle(document.documentElement).getPropertyValue(`--${variable}`).trim();

export interface LoadingSpinnerProps {
  size?: LoadingSpinnerSize;
  variant?: LoadingSpinnerVariant;
  className?: string;
}

export const LoadingSpinner = forwardRef(
  ({ size = 'md', variant = 'primary', className }: LoadingSpinnerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const dataUri = useMemo(() => {
      const sizePx = sizeToPx[size];
      const color = `hsl(${getColor(variant)})`;
      const encodedSVG = encodeURIComponent(`
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="${sizePx}"
      height="${sizePx}"
      viewBox="0 0 24 24"
      fill="none"
      stroke="${color}"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  `);
      return `data:image/svg+xml;charset=UTF-8,${encodedSVG}`;
    }, [size, variant]);

    return (
      <div ref={ref} className={cn('animate-spin block', className)}>
        <img src={dataUri} alt="Loading spinner" />
      </div>
    );
  },
);
